import React, { useContext } from 'react'
import classNames from 'classnames'
import btnDeleted from '../../assets/btnDeleted.svg'
import { useNavigate } from 'react-router-dom'
import UserContext from 'contexts/UserContext'
import localforage from 'localforage'
import logOutImage from '../../assets/logOutImage.svg'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'
import RechargeContext from 'contexts/rechargeContext'
import paimentFactureContext from 'contexts/paimentFactureContext'

interface Props {
  isOpen: boolean
  closeModal: () => void
  openModalLogOut: boolean
}

const ModalLogOutContainer: React.FC<Props> = ({ isOpen, closeModal, openModalLogOut }) => {
  if (!isOpen) return null
  const navigate = useNavigate()
  const { setUser } = useContext(UserContext)
  const { setTransferData } = useContext(transferBeneficiaryContext)
  const { setRecharges } = useContext(RechargeContext)
  const { setPaimentFacture } = useContext(paimentFactureContext)



  return (
    <div>
      {openModalLogOut && (
        <div
          className={classNames(
            'fixed justify-center items-center flex flex-col inset-0 z-50 bg-[#4945c12f] backdrop-blur-sm',
          )}
        >
          <div
            className={classNames(
              'rounded-xl relative py-3  flex flex-col w-[408px] sm:w-[350px] bg-image-bg-beneficiary bg-cover bg-no-repeat',
            )}
          >
           
            <div className="p-5 relative flex flex-col items-center text-center">
            
              <div className="font-bold text-[19px] text-[#1D3A70] mt-8">Déconnexion</div>
              <div className="text-[14px] font-normal text-[#6B7280] mt-5">
                Êtes-vous sûr de vouloir vous déconnecter ?
              </div>
              <div className="flex justify-center mt-8">
                <button
                  className="text-white text-[16px] font-semibold bg-[#4743C9] p-4 rounded-xl"
                  onClick={() => {
                    localforage.clear()
                    setUser(null)
                    setTransferData(null)
                    setRecharges(null)
                    setPaimentFacture(null)
                    closeModal()
                    navigate('/login')
                  }}
                >
                  Je confirme
                </button>
                <button
                  className="text-white text-[16px] font-semibold bg-[#F00] p-4 rounded-xl ml-3"
                  onClick={closeModal}
                >
                  Annuler
                </button>
              </div>
              <img
                className=" absolute -top-[85px] rounded-full shadow-xl "
                width={110}
                height={110}
                src={logOutImage}
              />
            </div>
           
            
          </div>
          
          {/* <div className="absolute "> */}
            {/* <div className="relative"> */}
              
            {/* </div> */}
          {/* </div> */}
        </div>
      )}
    </div>
  )
}

export default ModalLogOutContainer
