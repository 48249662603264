import React from 'react'
import classNames from 'classnames'

interface props {
  numStep: number
  nomStep: string
  isActive: boolean
  lastStep?: boolean
  isComplete?: boolean
}

const Step: React.FC<props> = ({ numStep, nomStep, isActive, lastStep, isComplete }) => {
  return (
    <div className="flex items-baseline justify-between ">
        <div className="flex flex-col items-center  sm:w-full">
          <div className={classNames(isActive ? 'bg-[#4743C9]' : 'bg-[#B4B4B4]',` text-white w-8 h-8 rounded-full flex items-center justify-center `)}>
          {numStep}
          </div>
          <div className={classNames(isActive ? 'text-[#4743C9]' : 'text-[#B4B4B4]', 'sm:text-[14px] text-center pt-3')}>{nomStep}</div>
        </div>
      {lastStep && <div className={classNames(isActive ? 'bg-[#4743C9]' : 'bg-[#B4B4B4]', ` h-[2px] w-[150px] sm:w-[90px]  `)}></div>}
    </div>
  )
}
export default Step
