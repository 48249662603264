import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import RadioFocus from '../../assets/radioFocus.svg'
import RadioON from '../../assets/radioON.svg'
import logoTT from '../../assets/logoTT.svg'
import logoOrangeNew from '../../assets/logoOrangeNew.svg'
import logoOoredooNew from '../../assets/logoOoredooNew.svg'
import OffreMaroc from './offreMaroc'
import UserContext from 'contexts/UserContext'
import { useNavigate } from 'react-router-dom'
import ModalTestStatusUser from 'components/modalTestStatusUser'
import RechargeContext from 'contexts/rechargeContext'

interface props {
  isOoredoo: boolean
  isOrange: boolean
  isTelecom: boolean
  isOrangeMaroc: boolean
  isTTMaroc: boolean
  isInwi: boolean
  product: any
  onChangeStep: any
  onBackChangeStep: any
  productMaroc: any
  onChangeVal: (value3: string, value2: string) => void
  setMontantTnd: (value: string) => void
  setMontantEur: (value: string) => void
  setSkuCode: (value: string) => void
  setMontantDinar: (value: string) => void
  FNPayer: any
  functionTracks: any
  selectedValue: any
  montantTnd: string
  montantDinar: string
  montantEur: string
  skuCode: string
}
const Offre: React.FC<props> = ({
  isOoredoo,
  isOrange,
  isTelecom,
  onChangeVal,
  product,
  onChangeStep,
  onBackChangeStep,
  setMontantTnd,
  setMontantEur,
  setSkuCode,
  setMontantDinar,
  isOrangeMaroc,
  isTTMaroc,
  isInwi,
  productMaroc,
  FNPayer,
  functionTracks,
  montantTnd,
  montantDinar,
  montantEur,
  skuCode,
}) => {
  const { user } = useContext(UserContext)
  const { recharges, setRecharges } = useContext(RechargeContext)

  const navigate = useNavigate()

  const [openIsModalStatus, setOpenIsModalStatus] = useState(false)

  const openModalStatus = () => {
    setOpenIsModalStatus(true)
  }
  const closeModalStatus = () => {
    setOpenIsModalStatus(false)
  }

  const [ON, setON] = useState(false)
  const [val, setVal] = useState<any>()

  const [indexRecharge, setIndexRecharge] = useState(-1)
  const [indexRechargeMaroc, setIndexRechargeMaroc] = useState(-1)

  const statusComplete = user?.user?.status === 'confirmed' && user?.user?.isDocumentSent
  const statusPending = user?.user?.status === 'pending' && user?.user?.isDocumentSent
  const statusNotFound = user?.user?.status === 'pending' && !user?.user?.isDocumentSent

  useEffect(() => {
    if (!recharges) return
    if (recharges) {
      setMontantTnd(recharges?.MontantTnd || '')
      setMontantDinar(recharges?.MontantDinar || '')
      setMontantEur(recharges?.MontantEur || '')
      setVal(recharges?.indexRechargeTN)
      setIndexRecharge(recharges?.indexRechargeTN || recharges?.indexRechargeTN === 0 ? recharges?.indexRechargeTN : -1)
      setON(recharges?.isOn || false)
    }
  }, [recharges, montantTnd, montantDinar, montantEur, val, ON, indexRecharge])

  return (
    <div className="h-full flex flex-col">
      <div className="flex flex-wrap lg:max-h-full xs:max-h-[340px] lg:overflow-y-auto no-scrollbar  justify-center items-center gap-x-[20%] 2xl:gap-x-[10%] lg:gap-x-[5%] gap-[15px] lg:mt-[5%] ">
        {product &&
          product.map((item: any, index: any) => (
            <React.Fragment key={index}>
              <div
                className={classNames(
                  `w-1/3 sm:w-[300px] ${isTelecom ? ' px-10' : 'hidden'} ${item.ProviderCode === 'TWTN' ? '' : 'hidden'}`,
                )}
                onClick={() => {
                  setMontantTnd(item.Maximum.SendCurrencyIso),
                    setMontantDinar(item.Maximum.ReceiveValue),
                    setMontantEur(item.Maximum.SendValue),
                    setSkuCode(item.SkuCode),
                    setRecharges({
                      ...recharges,
                      MontantTnd: item.Maximum.SendCurrencyIso,
                      MontantDinar: item.Maximum.ReceiveValue,
                      MontantEur: item.Maximum.SendValue,
                      indexRechargeTN: index,
                      SkuCode: item.SkuCode,
                      isOn: true,
                    })
                }}
              >
                <div className="flex flex-col items-center w-fill-available">
                  <div
                    className="flex flex-col bg-[#264B92] px-5 py-3 rounded-2xl w-[240px] hover:cursor-pointer"
                    onClick={() => {
                      onChangeVal(item.Maximum.ReceiveValue, item.Maximum.SendValue)
                      setVal(indexRecharge !== index ? setIndexRecharge(index) : setIndexRecharge(-1))
                      setON(true)
                      setRecharges({ ...recharges, indexRechargeTN: index, isOn: true })
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <img src={logoTT} />
                      <div className="relative items-center">
                        <img src={RadioFocus} className="w-6 h-6" />
                        <img
                          src={RadioON}
                          className={classNames(
                            (ON && indexRecharge === index) || (recharges?.isOn && recharges?.indexRechargeTN === index)
                              ? 'absolute inset-[25%] w-3 h-3'
                              : 'hidden',
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="relative mt-5">
                        <span className="text-white text-[20px] font-normal">{item.Maximum.ReceiveValue}</span>
                        <span className="text-white absolute bottom-[1rem] font-bold text-[18px]">
                          {item.Maximum.ReceiveCurrencyIso}
                        </span>
                      </div>

                      <div className="relative mt-5  mr-[25px]">
                        <span className="text-white font-bold text-[19px]">
                          {item.Maximum.SendValue} {item.Maximum.SendCurrencyIso}
                        </span>
                        <span className="text-white absolute bottom-[1.5rem] whitespace-nowrap left-1 text-[12px]">
                          Montant envoyé
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  `w-1/3 sm:w-[300px] ${isOrange ? '' : 'hidden'} ${item.ProviderCode === 'ORTN' ? '' : 'hidden'}`,
                )}
                onClick={() => {
                  setMontantTnd(item.Maximum.SendCurrencyIso),
                    setMontantEur(item.Maximum.SendValue),
                    setMontantDinar(item.Maximum.ReceiveValue),
                    setSkuCode(item.SkuCode),
                    setRecharges({
                      ...recharges,
                      MontantTnd: item.Maximum.SendCurrencyIso,
                      MontantDinar: item.Maximum.ReceiveValue,
                      MontantEur: item.Maximum.SendValue,
                      indexRechargeTN: index,
                      SkuCode: item.SkuCode,
                      isOn: true,
                    })
                }}
              >
                <div className={`flex flex-col items-center`}>
                  <div className="flex flex-col items-center">
                    <div className="flex flex-col items-center">
                      <div
                        className="flex flex-col bg-black px-5 py-3 rounded-2xl w-[250px] hover:cursor-pointer"
                        onClick={() => {
                          onChangeVal(item.Maximum.ReceiveValue, item.Maximum.SendValue)
                          setVal(indexRecharge !== index ? setIndexRecharge(index) : setIndexRecharge(-1))
                          setON(true)
                          setRecharges({ ...recharges, indexRechargeTN: index, isOn: true })
                        }}
                      >
                        <div className="flex justify-between items-center">
                          <img src={logoOrangeNew} />
                          <div className="relative items-center">
                            <img src={RadioFocus} className="w-6 h-6" />
                            <img
                              src={RadioON}
                              className={classNames(
                                (ON && indexRecharge === index) ||
                                  (recharges?.isOn && recharges?.indexRechargeTN === index)
                                  ? 'absolute inset-[25%] w-3 h-3'
                                  : 'hidden',
                              )}
                            />
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="relative mt-5">
                            <span className="text-white text-[20px] font-normal">{item.Maximum.ReceiveValue}</span>
                            <span className="text-white absolute bottom-[1rem] font-bold text-[18px]">
                              {item.Maximum.ReceiveCurrencyIso}
                            </span>
                          </div>

                          <div className="relative mt-5 mr-[25px]">
                            <span className="text-white font-bold text-[19px]">
                              {item.Maximum.SendValue} {item.Maximum.SendCurrencyIso}
                            </span>
                            <span className="text-white absolute bottom-[1.5rem] whitespace-nowrap left-1 text-[12px]">
                              Montant envoyé
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={classNames(
                  `w-1/3 sm:w-[300px] ${isOoredoo ? '' : 'hidden'} ${item.ProviderCode === 'TUTN' ? '' : 'hidden'}`,
                )}
                onClick={() => {
                  setMontantTnd(item.Maximum.SendCurrencyIso),
                    setMontantEur(item.Maximum.SendValue),
                    setMontantDinar(item.Maximum.ReceiveValue),
                    setSkuCode(item.SkuCode),
                    setRecharges({
                      ...recharges,
                      MontantTnd: item.Maximum.SendCurrencyIso,
                      MontantDinar: item.Maximum.ReceiveValue,
                      MontantEur: item.Maximum.SendValue,
                      indexRechargeTN: index,
                      SkuCode: item.SkuCode,
                      isOn: true,
                    })
                }}
              >
                <div className={`flex flex-col items-center`}>
                  <div className="flex flex-col items-center">
                    <div className="flex flex-col items-center w-fill-available">
                      <div
                        className="flex flex-col bg-[#EC1C24] px-5 py-3 rounded-2xl w-[250px] hover:cursor-pointer"
                        onClick={() => {
                          onChangeVal(item.Maximum.ReceiveValue, item.Maximum.SendValue)
                          setVal(indexRecharge !== index ? setIndexRecharge(index) : setIndexRecharge(-1))
                          setON(true)
                          setRecharges({ ...recharges, indexRechargeTN: index, isOn: true })
                        }}
                      >
                        <div className="flex justify-between items-center">
                          <img src={logoOoredooNew} />
                          <div className="relative items-center">
                            <img src={RadioFocus} className="w-6 h-6" />
                            <img
                              src={RadioON}
                              className={classNames(
                                (ON && indexRecharge === index) ||
                                  (recharges?.isOn && recharges?.indexRechargeTN === index)
                                  ? 'absolute inset-[25%] w-3 h-3'
                                  : 'hidden',
                              )}
                            />
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="relative mt-5">
                            <span className="text-white text-[20px] font-normal">{item.Maximum.ReceiveValue}</span>
                            <span className="text-white absolute bottom-[1rem] font-bold text-[18px]">
                              {item.Maximum.ReceiveCurrencyIso}
                            </span>
                          </div>

                          <div className="relative mt-5 mr-[25px]">
                            <span className="text-white font-bold text-[19px]">
                              {item.Maximum.SendValue}
                              {''}
                              {item.Maximum.SendCurrencyIso}
                            </span>

                            <span className="text-white absolute bottom-[1.5rem] whitespace-nowrap left-1 text-[12px]">
                              Montant envoyé
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
      </div>

      <OffreMaroc
        isOrangeMaroc={isOrangeMaroc}
        isTTMaroc={isTTMaroc}
        isInwi={isInwi}
        productMaroc={productMaroc}
        indexRechargeMaroc={indexRechargeMaroc}
        setIndexRechargeMaroc={setIndexRechargeMaroc}
        onChangeVal={onChangeVal}
        setMontantTnd={setMontantTnd}
        setMontantEur={setMontantEur}
        setMontantDinar={setMontantDinar}
        setSkuCode={setSkuCode}
        montantTnd={montantTnd}
        montantDinar={montantDinar}
        montantEur={montantEur}
      />

      <div className={`flex justify-center py-6 sm:py-0 gap-[5%]`}>
        <button
          className={classNames(
            'text-[18px] text-[#556987] p-2 h-[56px] w-[140px] border border-[#556987] font-sans rounded-xl',
          )}
          onClick={onBackChangeStep}
        >
          Précédent
        </button>
        <button
          className={classNames(
            'text-[18px] text-[#FFF] w-[357px] p-2 h-[56px] rounded-xl  sm:w-[180px]',
            (ON && indexRecharge === -1) || (!ON && indexRechargeMaroc === -1) ? 'bg-gray-400' : 'bg-[#4743C9] ',
          )}
          onClick={() => {
            if (statusComplete) {
              onChangeStep()
              functionTracks('2')
              FNPayer()
            } else if (statusPending) {
              console.log('statusPending')
              openModalStatus()
            } else if (statusNotFound) {
              navigate('/personalinformation')
            }
          }}
          disabled={(ON && indexRecharge === -1) || (!ON && indexRechargeMaroc === -1)}
        >
          Suivant
        </button>
      </div>
      <ModalTestStatusUser
        isOpen={openIsModalStatus}
        closeModal={closeModalStatus}
        statusPending={statusPending}
        statusNotFound={statusNotFound}
      />
    </div>
  )
}
export default Offre
