import React, { useState, useRef, useEffect, useContext } from 'react'
import ArrowDown from '../assets/arrowDown.svg'
import classNames from 'classnames'
import useClickOutside from '../hooks/useOnclickOutside'
import iconSelectInput from '../assets/iconSelectInput.svg'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'

interface PropsInputSelect {
  value: string
  icon?: boolean
  data: any
  setItem: (selectedValue: string, idValue: string) => void
  isObjectData?: boolean
  width?: string
  placeholder?: string
  widthValue?: string
  keyOfIndex?: string
  keyOfIndex1?: string
  isDisabled?: boolean
  euroValue?: any
  partnersData?: any
  previousId?: any
  setPreviousId?: any
  setSaveIdGov?: any
  setReseaux?: any
  setSelectedOptionPointCollecte?: any
  setValueGov: any
  setValueVille: any
  setSelectedOptionReseaux: any
  getListGovernorate: any
  functionGetListByGovernorate: any
  setGetGovernorate: any
  getListPartenaireByCity: any
  setGetCity:any
  getGovernorate:string
}
const InputSelect: React.FC<PropsInputSelect> = ({
  value,
  icon = true,
  data,
  setItem,
  isObjectData = false,
  width = 'w-[290px]',
  placeholder,
  widthValue,
  keyOfIndex = 'name',
  keyOfIndex1,
  isDisabled,
  euroValue,
  partnersData,
  previousId,
  setPreviousId,
  setSaveIdGov,
  setReseaux,
  setSelectedOptionPointCollecte,
  setValueGov,
  setValueVille,
  setSelectedOptionReseaux,
  getListGovernorate,
  functionGetListByGovernorate,
  setGetGovernorate,
  getListPartenaireByCity,
  setGetCity,
  getGovernorate
}) => {
  const { TransferData, setTransferData } = useContext(transferBeneficiaryContext)

  const [openGovernorate, setOpenGovernorate] = useState(false)
  const divRef = useRef<HTMLDivElement>(null)
  const dropDownRef = useRef<HTMLDivElement>(null)
  const toggleSetting = () => setOpenGovernorate(false)
  useClickOutside(toggleSetting, dropDownRef, divRef)

  // const [searchTerm, setSearchTerm] = useState('')
  // const [indexGovernorate, setIndexGovernorate] = useState(-1)

  // const handleSearchChange = (event: any) => {
  //   setSearchTerm(event.target.value)
  // }

  // const filterGovernorate = () => {
  //   return getListGovernorate.filter((item: any) => item.toLowerCase().includes(searchTerm.toLowerCase()))
  // }

  const handleClickOutside = (event:any) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setOpenGovernorate(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (
      euroValue > 1000 &&
      TransferData?.partenaire !== 'izi' &&
      TransferData?.partenaire !== 'Wafacash' &&
      getListPartenaireByCity.filter((item: any) => item.network !== 'izi' && item.network !== 'Wafacash')
    ) {
      setValueGov('')
      setValueVille('')
    }
  }, [])

  useEffect(()=>{
    if(value !== ''){
      setGetGovernorate(value)
    }
  },[value, getGovernorate])

  //console.log('TransferData', TransferData)

  return (
    <div className={classNames(width, 'flex flex-col gap-1 relative w-full 2sm:w-[340px]')}>
      <div className="sm:mx-0 md:mx-0 2sm:ml-6" 
        //onClick={() => (isDisabled ? {} : setOpenGovernorate(!openGovernorate))}
      >
        <div className="flex flex-col w-full ">
          <div className="relative inline-block mt-3 hover:cursor-pointer" ref={dropDownRef}>
            <input
              type="text"
              className="p-2 border hover:cursor-pointer font-bold text-[15px] sm:h-[40px] rounded-xl w-full h-[56px] text-[#1D3A70] placeholder:text-[#1D3A70]"
              placeholder={value ? value : placeholder}
              onClick={() => {
                setOpenGovernorate(!openGovernorate)
              }}
              value={getGovernorate || value}
              // onChange={(e) => {
              //   handleSearchChange(e)
              //   setReseaux('')
              //   setSelectedOptionPointCollecte('')
              //   setSelectedOptionReseaux('')
              // }}
              name="selectedOptionMotif"
              autoComplete="off"
            />
            <div className="absolute top-0 right-0 mt-[15px] 2sm:mt-[10px] mx-3" onClick={() => setOpenGovernorate(!openGovernorate)}>
              <img src={iconSelectInput} className="h-6 w-6" onClick={() => setOpenGovernorate(!openGovernorate)} />
            </div>
            {openGovernorate && (
              <div
                className="flex flex-col rounded z-10 absolute min-h-auto max-h-[150px] overflow-auto bg-white  shadow-selct w-full"
                //ref={dropDownRef}
              >
                {getListGovernorate?.map((item: any, index: number) => (
                  <span
                    key={index}
                    className={`hover:cursor-pointer py-2 text-[14px] pl-4 ${
                      item === value ? 'font-semibold text-[#1D3A70] bg-[#F5F5F5]' : 'text-[#424242] font-semibold'
                    }`}
                    onClick={() => {
                      setItem(item, item._id)
                      setGetGovernorate(item)
                      setOpenGovernorate(false)
                      if(item !== value){
                        setGetCity('')
                        setReseaux('')
                        setSelectedOptionPointCollecte('')
                        setSelectedOptionReseaux('')
                        setTransferData({...TransferData,governorate:item, ville:'', partenaire:'', pointDeCollect:''})
                      }
                    }}
                  >
                    {item}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default InputSelect
