import React from 'react'

interface Props {
  image: string
  SignUp?:string
}

const ImageSignUp: React.FC<Props> = ({ image , SignUp}) => {
  return (
    <>
      <img className={`${SignUp ? SignUp : ''} w-[50%] flex sm:hidden lg:hidden object-cover`} src={image} alt="" />
    </>
  )
}

export default ImageSignUp
