import React, { useState, useContext, useEffect } from 'react'
import UserContext from 'contexts/UserContext'
import { AddBeneficiary } from '../../helpers/api'
import { useFormik } from 'formik'
import * as yup from 'yup'
import drapeauTunisie from '../../assets/drapeauTunisie.svg'
import iconSelectInput from '../../assets/iconSelectInput.svg'
import updateImage from '../../assets/updateImage.svg'
import CustomSelect from 'components/CustomSelect'
import ModalImageBeneficiary from './modalImageBeneficiary'
import imageDefault from '../../assets/imageDefault.jpeg'
import drapeauMaroc from '../../assets/drapeauMaroc.svg'
import BeneficiaryContext from 'contexts/beneficiaryContext'
import { Beneficiary } from 'types/beneficiary'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'
interface Props {
  // setBeneficiaryData?: (value: any) => void
  closeModal: () => void
  // setNewImageSelected?: any
  setIsAdded: any
  // selectedImageId?: any
  // setSelectedImageId?: any
  // dataAvatar?: any
  // newImage?: any
  // setNewImage?: any
  valuePays?: any
  code?: any
}

const ModalAddBeneficiary: React.FC<Props> = ({
  closeModal,
  // setNewImageSelected,
  setIsAdded,
  // selectedImageId,
  // setSelectedImageId,
  // dataAvatar,
  // newImage,
  // setNewImage,
  valuePays,
  code,
}) => {
  const { user } = useContext(UserContext)
  const { TransferData, setTransferData } = useContext(transferBeneficiaryContext)
  const [selectedImageId, setSelectedImageId] = useState<number | null>(null)
  const [newImage, setNewImage] = useState('')
  const number = parseInt(code)

  const [selectedOption, setSelectedOption] = useState('')
  const [selectedOptionGenre, setSelectedOptionGenre] = useState('')
  const [selectedOptionLien, setSelectedOptionLien] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenGenre, setIsOpenGenre] = useState(false)
  const [isOpenLien, setIsOpenLien] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [selectedValue, setSelectedValue] = useState<string>('+216')
  const [firstSubmit, setFirstSubmit] = useState(true)
  const [choisirImage, setChoisirImage] = useState(false)
  const [responseAddBeneficiary, setResponseAddBeneficiary] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const closeModalImage = () => {
    setChoisirImage(false)
  }

  const handleImageClick = (index: number) => {
    setSelectedImageId(index)
  }

  const optionGenre = [
    { index: 26, code: 'female', name: 'Femme' },
    { index: 25, code: 'male', name: 'Homme' },
  ]

  const optionsLien = [
    { index: 0, code: 'FAMILY', name: 'Famille' },
    { index: 1, code: 'FRIEND', name: 'Amis' },
    { index: 2, code: 'SELF', name: 'Moi même' },
    { index: 3, code: 'OTHER', name: 'Autres' },
  ]

  const options: { icon: any; value: string; label: string }[] = [
    { icon: drapeauTunisie, value: '+216', label: '+216' },
    { icon: drapeauMaroc, value: '+212', label: '+212' },
  ]

  const handleLienChange = (selectedOption: any) => {
    const userGender = user?.user?.personalInfo?.gender
    const genderOption = optionGenre.find((option) => option.code === userGender)?.code || ''

    if (selectedOption.code === 'SELF') {
      formik.setFieldValue('nom', user?.user?.personalInfo?.lastName)
      formik.setFieldValue('prenom', user?.user?.personalInfo?.firstName)
      setSelectedOptionGenre(genderOption)
      formik.setFieldValue('selectedOptionGenre', genderOption)
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }

  const handleSelect = (option: any) => {
    setSelectedOption(option.value)
    formik.setFieldValue('selectedOptionLien', option.code)
    handleLienChange(option)
  }

  const handleSelectGenre = (genre: any) => {
    setSelectedOptionGenre(genre)
    formik.setFieldValue('selectedOptionGenre', genre)
  }

  const handleSelectLien = (lien: any) => {
    setSelectedOptionLien(lien)
  }

  const initialFormData = {
    avatar: '',
    newImage: '',
    nom: '',
    prenom: '',
    telephone: '',
    prefix: '',
    selectedOptionLien: '',
    selectedOption: '',
    selectedOptionGenre: '',
  }

  const [formData, setFormData] = useState(initialFormData)

  const validationSchema = yup.object().shape({
    nom: yup
      .string()
      .matches(/^[a-zA-Z\u0600-\u06FF\s]+$/, 'Le nom doit contenir uniquement des lettres')
      .required('Ce champ est requis'),
    prenom: yup
      .string()
      .matches(/^[a-zA-Z\u0600-\u06FF\s]+$/, 'Prenom doit contenir uniquement des lettres')
      .required('Ce champ est requis'),
    telephone: yup
      .string()
      .matches(/^\+?[0-9]+$/, 'Numéro de téléphone invalide')
      .test('len', 'Téléphone obligatoire', (value) => !!value)
      .test('len', 'Le numéro doit contenir 9 chiffres', (value: any) => {
        if (selectedValue.startsWith('+212')) {
          return value.length === 9
        }
        return true
      })
      .test('len', 'Le numéro doit contenir 8 chiffres', (value: any) => {
        if (selectedValue.startsWith('+216')) {
          return value.length === 8
        }
        return true
      })
      .required('Ce champ est requis'),

    selectedOptionLien: yup.string().required('Ce champ est requis'),
    selectedOptionGenre: yup.string().required('Ce champ est requis'),
  })
  const handleSubmit = async (values: typeof initialFormData) => {
    if (!user || !user.user || !user.token) {
      console.error('User information is missing.')
      return
    }
    const id = user.user._id
    const token = user?.token?.accessToken

    if (formik.isValid) {
      const newBeneficiary: any = {
        avatar: selectedImageId,
        firstName: values.prenom,
        lastName: values.nom,
        phonePrefix: selectedValue.slice(1),
        phoneNumber: values.telephone,
        relationship: values.selectedOptionLien,
        gender: values.selectedOptionGenre,
      }
      setDisabled(true)
      setFormData(initialFormData)
      try {
        await AddBeneficiary(id, token, newBeneficiary)
          .then(async (response: any) => {
            if (response?.success) {
              setTransferData({
                ...TransferData,
                BeneficiaryName: `${response?.data?.personalInfo?.firstName} ${response?.data?.personalInfo?.lastName}`,
                idBeneficiary: response?.data?._id,
              })
              closeModal()
              //console.log('response', response)
              if (setIsAdded) {
                setIsAdded()
              }
              setFirstSubmit(false)
              setNewImage(newImage)
            } else if (!response?.success) {
              setResponseAddBeneficiary(true)
              closeModal()
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } catch (error) {
        console.error('Error adding beneficiary:', error)
      }
    } else {
      console.log('Le formulaire contient des erreurs')
    }
  }

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  const handleSelectChange = (selectedValue: string) => {
    setSelectedValue(selectedValue)
  }

  useEffect(() => {
    if (valuePays && valuePays.value === 'TND') {
      setSelectedValue('+216')
    } else if (valuePays && valuePays.value === 'MAD') {
      setSelectedValue('+212')
    } else if (user?.user?.destinationDefaultCountry === 'TUN' && selectedValue !== '+216') {
      setSelectedValue('+216')
    } else if (user?.user?.destinationDefaultCountry === 'MAC' && selectedValue !== '+212') {
      setSelectedValue('+212')
    } else {
      setSelectedValue(selectedValue)
    }
  }, [valuePays])
  console.log('newImage',newImage)
  

  return (
    <div className="flex flex-col items-center justify-center mx-[25px] mt-[25px] mb-auto sm:mx-0 sm:mt-1 sm:pb-[5px]">
      {responseAddBeneficiary ? (
        <div className="relative my-2 mr-5 p-2 shadow max-w-max">
          <div className="absolute left-0 w-1 h-[25px] bg-[#e63131] ml-1"></div>
          <div className="relative z-10 pl-2 font-bold text-[18px] text-[#1D3A70]">
            Échec de l&apos;ajout du bénéficiaire
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="">
        <div className="relative">
          {selectedImageId ? (
            <img
              src={newImage}
              className="w-[132px] h-[130px] rounded-full border-[#F9FAFB] border-8 sm:w-[100px] sm:h-[105px] object-cover"
            />
          ) : (
            <img
              src={imageDefault}
              className="w-[132px] h-[130px] rounded-full border-[#F9FAFB] border-8 sm:w-[100px] sm:h-[105px] object-cover"
              alt="Selected Image"
            />
          )}

          <div className="flex items-center space-x-4 absolute right-0 bottom-0">
            <label htmlFor="fileInput" className="cursor-pointer p-1 text-center text-[10px]">
              <img src={updateImage} className="w-8 h-8" onClick={() => setChoisirImage(true)} />
            </label>
          </div>

          <ModalImageBeneficiary
            isOpen={choisirImage}
            newImage={newImage}
            setNewImage={setNewImage}
            closeModal={closeModalImage}
            selectedImageId={selectedImageId}
            handleImageClick={handleImageClick}
          />
        </div>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className="grid sm:grid-cols-1 grid-cols-2 sm:gap-y-0 gap-x-[45px] my-5 sm:my-0 md:my-0 lg:mx-[5%] sm:mx-[0px] md:mx-[0px]"
      >
        <label className="h-[120px] sm:h-[70px] sm:mt-[10px]">
          <div className="flex items-center sm:hidden 2sm:hidden xs:hidden">
            <span className="mt-[10px] text-[#1D3A70] font-bold text-[16px] sm:mt-1 md:mt-1">
              Prénom
              <span className="text-red-500 ">*</span>
            </span>
          </div>
          <input
            className="bg-[#F9FAFB] rounded-xl py-[14px] mxm:py-[7px] sm:h-[46px] text-[#1D3A70] placeholder:text-[#6B7280] font-bold text-[15px] dimensionIpad:w-[295px] pl-[22px] sm:w-[320px] 2sm:w-[320px] w-full sm:mt-1 mt-[12px] md:mt-1"
            type="text"
            value={formik.values.prenom}
            placeholder="Prénom"
            name="prenom"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur('prenom')}
            autoComplete="off"
            disabled={isDisabled}
          />
          {formik.errors.prenom && formik.touched.prenom && (
            <div className="text-red-500 text-[12px]">{formik.errors.prenom}</div>
          )}
        </label>

        <label className="h-[120px] sm:h-[70px]">
          <div className="flex items-center sm:hidden 2sm:hidden xs:hidden">
            <span className="text-[#1D3A70] font-bold text-[16px] mt-[10px] md:mt-1">
              Nom <span className="text-red-500">*</span>
            </span>
          </div>
          <input
            className="bg-[#F9FAFB] font-bold text-[15px] sm:h-[46px]  sm:w-[320px] 2sm:w-[320px] rounded-xl py-[14px] pl-[26px] mxm:py-[7px] text-[#1D3A70] placeholder:text-[#6B7280] w-full sm:mt-1 mt-[12px] md:mt-1"
            type="text"
            name="nom"
            placeholder="Nom"
            value={formik.values.nom}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur('nom')}
            autoComplete="off"
            disabled={isDisabled}
          />
          {formik.errors.nom && formik.touched.nom && <div className="text-red-500 text-[12px]">{formik.errors.nom}</div>}
        </label>

        <label className="h-[120px] sm:h-[70px]">
          <div className="flex flex-col w-full">
            <span className="mt-[10px] text-[#1D3A70] font-bold text-[16px] md:mt-1 sm:hidden 2sm:hidden xs:hidden">
              Genre <span className="text-red-500">*</span>
            </span>
            <div className="relative inline-block mt-3">
              <input
                type="text"
                className="p-2 w-full h-[56px] bg-[#F9FAFB] hover:cursor-pointer sm:h-[46px] font-bold sm:w-[320px] 2sm:w-[320px] text-[15px] rounded-xl text-[#1D3A70] placeholder:text-[#6B7280]"
                placeholder="Choisir un genre"
                onClick={() => {
                  setIsOpenGenre(!isOpenGenre), setIsOpenLien(false)
                }}
                value={optionGenre.find((option) => option.code === selectedOptionGenre)?.name || ''}
                onChange={(e) => {
                  formik.setFieldValue('selectedOptionGenre', e.target.value)
                  handleSelect(e.target.value)
                }}
                autoComplete="off"
                onBlur={formik.handleBlur('selectedOptionGenre')}
                name="selectedOptionGenre"
                disabled={isDisabled}
                readOnly
              />
              <div className="absolute top-0 right-0 mt-[15px] mx-3" onClick={() => setIsOpenGenre(!isOpenGenre)}>
                <img src={iconSelectInput} className="h-6 w-6" />
              </div>

              <div
                className={`absolute top-full left-0 z-10 min-h-auto max-h-[150px] rounded-lg w-full shadow-selct text-[#1D3A70] bg-white overflow-y-auto ${
                  isOpenGenre && !isDisabled ? 'block' : 'hidden'
                }`}
              >
                {optionGenre.map((optionGenre) => (
                  <div
                    key={optionGenre.code}
                    className="p-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => {
                      formik.setFieldValue('selectedOptionGenre', optionGenre.code), handleSelectGenre(optionGenre.code)
                    }}
                  >
                    <div className="text-[#1D3A70] text-[15px] font-semibold">{optionGenre.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            {formik.errors.selectedOptionGenre && formik.touched.selectedOptionGenre && (
              <div className="text-red-500 text-[12px]">{formik.errors.selectedOptionGenre}</div>
            )}
          </div>
        </label>

        <label className="h-[120px] sm:h-[78px]">
          <div className="flex flex-col">
            <span className="mt-[10px] text-[#1D3A70] font-bold text-[16px] md:mt-1 sm:hidden 2sm:hidden xs:hidden">
              Lien <span className="text-red-500 ">*</span>
            </span>
            <div className="relative inline-block mt-3">
              <input
                type="text"
                className="p-2 bg-[#F9FAFB] font-bold text-[15px] hover:cursor-pointer sm:w-[320px] 2sm:w-[320px] rounded-xl w-full h-[56px] text-[#1D3A70] placeholder:text-[#6B7280]"
                placeholder="Choisir un lien"
                onClick={() => {
                  setIsOpenLien(!isOpenLien), setIsOpenGenre(false)
                }}
                value={selectedOptionLien}
                onChange={(e) => {
                  formik.setFieldValue('selectedOptionLien', e.target.value)
                  handleSelect(e.target.value)
                }}
                name="selectedOptionLien"
                onBlur={formik.handleBlur('selectedOptionLien')}
                autoComplete="off"
                readOnly
              />
              <div className="absolute top-0 right-0 mt-[15px] mx-3" onClick={() => setIsOpenLien(isOpenLien)}>
                <img src={iconSelectInput} className="h-6 w-6" />
              </div>

              <div
                className={`absolute top-full left-0 z-10 min-h-auto max-h-[150px] w-full shadow-selct rounded-lg text-[#1D3A70] bg-white overflow-y-auto ${
                  isOpenLien ? 'block' : 'hidden'
                }`}
              >
                {optionsLien.map((LienList) => (
                  <div
                    key={LienList.code}
                    className="p-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => {
                      formik.setFieldValue('selectedOptionLien', LienList.code)
                      handleSelectLien(LienList.name)
                      handleSelect(LienList)
                    }}
                  >
                    <div className="text-[#1D3A70] text-[15px] font-semibold">{LienList.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            {formik.errors.selectedOptionLien && formik.touched.selectedOptionLien && (
              <div className="text-red-500 text-[12px]">{formik.errors.selectedOptionLien}</div>
            )}
          </div>
        </label>
        <label className="flex flex-col h-[120px] sm:h-[70px]">
          <span className="text-[#1D3A70] font-bold text-[16px] mt-[10px] md:mt-1 sm:hidden 2sm:hidden xs:hidden">
            Préfixe <span className="text-red-500">*</span>
          </span>
          <div className="mt-3 hover:cursor-pointer">
            <CustomSelect
              options={options}
              value={options.find((item) => item.value === selectedValue)?.label || 'Préfix'}
              onChange={handleSelectChange}
              placeholder=""
              icon={options.find((item) => item.value === selectedValue)?.icon}
              width="w-[215px] sm:w-[320px] 2sm:w-[320px]"
              height="h-[47px] w-full sm:w-[320px] 2sm:w-[320px]"
              background="bg-[#F9FAFB] rounded-xl"
              textSize="text-[15px] font-bold"
              heightDropdown="h-[100px] z-[1]"
              textColor="text-[#6B7280]"
              iconSelect={iconSelectInput}
              marginRightIcon="mx-3"
              roundedIcon="rounded-none"
              showArrow={number === 1 || number === 0 ? false : true}
            />
          </div>
        </label>

        <label className="flex flex-col h-[120px] sm:h-[70px] w-[250px] sm:w-full">
          <span className="mt-[10px] text-[#1D3A70] font-bold text-[16px] md:mt-1 sm:hidden 2sm:hidden xs:hidden">
            Numéro de téléphone <span className="text-red-500">*</span>
          </span>
          <input
            className="bg-[#F9FAFB] rounded-xl py-[14px] mxm:py-[7px] sm:w-[320px] 2sm:w-[320px] sm:h-[46px] text-[#1D3A70] placeholder:text-[#6B7280] font-bold text-[15px] dimensionIpad:w-[295px] pl-[22px] w-full sm:mt-1 mt-[12px] md:mt-1"
            type="text"
            value={formik.values.telephone}
            placeholder={formik.values.telephone ? formik.values.telephone : 'Numéro de téléphone'}
            name="telephone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur('telephone')}
            autoComplete="off"
          />
          {formik.errors.telephone && formik.touched.telephone && (
            <div className="text-red-500 text-[12px]">{formik.errors.telephone}</div>
          )}
        </label>
        <button
          type="submit"
          value="Submit"
          disabled={
            formik.isSubmitting ||
            !(formik.isValid && formik.touched) ||
            (firstSubmit && !formik.dirty) ||
            // formik.values.telephone.length < 8 ||
            // formik.values.telephone.length > 8
            (selectedValue === '+212' && (formik.values.telephone.length < 9 || formik.values.telephone.length > 9)) ||
            (selectedValue === '+216' && (formik.values.telephone.length < 8 || formik.values.telephone.length > 8))
          }
          className={`px-[28px] py-[16px] sm:py-2 px mobile:mx-89p mt-[30px] sm:mb-[15px] sm:mt-2 sm:h-[46px] rounded-xl text-[#FFF] text-[18px] font-semibold mx-auto sm:w-[250px] w-[350px] dimensionIpad:w-[350px] customIpadAir:w-[335px] lg:w-[335px] xs:w-[200px] sm:col-span-1 col-span-2
            ${
              formik.isSubmitting ||
              (!formik.isValid && formik.touched) ||
              (firstSubmit && !formik.dirty) ||
              (selectedValue === '+212' &&
                (formik.values.telephone.length < 9 || formik.values.telephone.length > 9)) ||
              (selectedValue === '+216' && (formik.values.telephone.length < 8 || formik.values.telephone.length > 8))
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-[#4743C9]'
            }
          `}
        >
          Ajouter
        </button>
      </form>
      {/* <ModalConfirmedAjout
          isOpen={openModalConfirmed}
          closeModal={closeModal}
          openModalConfirmed={openModalConfirmed}
          setIsConfirmed={setIsConfirmed}
        /> */}
    </div>
  )
}

export default ModalAddBeneficiary
