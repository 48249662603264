import React, { useCallback, useContext, useState } from 'react'
import iconVisa from '../../assets/iconVisa.svg'
import verifiedbyvisamastercard from '../../assets/verifiedbyvisamastercard.svg'
import verifiedbyvisa from '../../assets/verifiedbyvisa.svg'
import { useFormik } from 'formik'
import * as yup from 'yup'
import UserContext from 'contexts/UserContext'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import PaiementMaroc from './paiementMaroc'

import { loadStripe } from '@stripe/stripe-js'
import { EmbeddedCheckoutProvider, EmbeddedCheckout, Elements } from '@stripe/react-stripe-js'
import PaiementForm from './paiementForm'

interface props {
  isOoredoo: boolean
  isOrange: boolean
  isTelecom: boolean
  isOrangeMaroc: boolean
  isTTMaroc: boolean
  isInwi: boolean
  productMaroc: any
  onChangeVal: (value3: string, value2: string) => void
  product: any
  rechargeMobileCarte: any
  rechargeCarte: any
  setRechargeCarte: any
  setRechargeMobileCarte: any
  ProviderCode: string
  onBackChangeStep: any
  setNumCarte: any
  setCodeCVC: any
  setMonth: any
  setYear: any
  SendValue: any
  FNPayer: () => void
  onChangeStep: any
  clientSecret: string
  confirmPayment: any
  buttonClicked: boolean
  selectionCount: number
  functionTracks: any
}

const PaimentContainer: React.FC<props> = ({
  isOoredoo,
  isOrange,
  isTelecom,
  onChangeVal,
  product,
  rechargeMobileCarte,
  rechargeCarte,
  setRechargeCarte,
  setRechargeMobileCarte,
  ProviderCode,
  onBackChangeStep,
  setNumCarte,
  setCodeCVC,
  setMonth,
  setYear,
  SendValue,
  FNPayer,
  isOrangeMaroc,
  isTTMaroc,
  isInwi,
  productMaroc,
  clientSecret,
  confirmPayment,
  buttonClicked,
  selectionCount,
  functionTracks,
}) => {
  const stripePromise = loadStripe(
    'pk_test_51OzdEkP1vfmwjObRWZs9jeUgtjVr5yPKXnOEtrKZbJdd7boKLUA7KP6VVEmXkqJJegtrebeTm3z9plymQavS3ZwL009Vrh8Udc',
    //'pk_live_51OzdEkP1vfmwjObRvRrgzAJK6t50avVcC79ukYvChcLYpCQPS6fJenImQi1dJgkZQYzFtmyu4TJ3Wjbqzq2MiGKu00syVqIEXn'
  )

  return (
    // <div className="sm:mt-5 xs:mt-5 5xl:flex 5xl:justify-center xs:flex xs:justify-center w-full">
      <div className="w-full">
        <Elements stripe={stripePromise}>
          <PaiementForm
            clientSecret={clientSecret}
            confirmPayment={confirmPayment}
            SendValue={SendValue}
            ProviderCode={ProviderCode}
            isTelecom={isTelecom}
            isOrange={isOrange}
            isOoredoo={isOoredoo}
            buttonClicked={buttonClicked}
            selectionCount={selectionCount}
            functionTracks={functionTracks}
          />
        </Elements>
      {/* </div> */}
      {/* <PaiementMaroc
        isOrangeMaroc={isOrangeMaroc}
        isTTMaroc={isTTMaroc}
        isInwi={isInwi}
        productMaroc={productMaroc}
        ProviderCode={ProviderCode}
        setNumCarte={setNumCarte}
        setCodeCVC={setCodeCVC}
        setYear={setYear}
        SendValue={SendValue}
        FNPayer={FNPayer}
        functionTracks={functionTracks}
        confirmPayment={confirmPayment}
      /> */}
    </div>
  )
}
export default PaimentContainer
