import React, { useState } from 'react'
import classNames from 'classnames'
import Groupe from '../assets/Group.svg'
import Vector from '../assets/Vector.svg'
import Close from '../assets/close.svg'
import { Link, useNavigate } from 'react-router-dom'
import HomeIcon from '../assets/home.svg'
import HistoriqueIcon from '../assets/historique.svg'
import RechargeIcon from '../assets/recharge.svg'
import TransfertIcon from '../assets/transfert.svg'
import BenifIcon from '../assets/beneficiare.svg'
import LogOutIcon from '../assets/logout.svg'
import ModalLogOutContainer from 'containers/logOutContainer/modalLogOutContainer'
import logoMyEasyMobile from '../assets/logoMyEasyMobile.svg'
import iconAssuranceResponsive from '../assets/iconAssuranceResponsive.svg'

import assuranceIconBanque from '../assets/assuranceIconBanque.svg'
import compteBanqueIconMobile from '../assets/compteBanqueIconMobile.svg'
import HistoriqueIconMobile from '../assets/HistoriqueIconMobile.svg'
import PaiementIconManque from '../assets/PaiementIconManque.svg'

export default function NavbarMobile() {
  const navigate = useNavigate()
  
  const[openModalLogOut, setOpenModalLogOut] = useState(false)
  const closeModal = () =>{
    setOpenModalLogOut(false)
  }

  const [open, setOpen] = useState(true)
  const Links: { img: any; name: string; link: string }[] = [
    { img: HomeIcon, name: 'Accueil', link: '/' },
    { img: HistoriqueIconMobile, name: 'Historique', link: '/History' },
    { img: RechargeIcon, name: 'Recharge mobile', link: '/recharge' },
    { img: TransfertIcon, name: "Transfert retrait cash", link: '/transfert/0' },
    { img: compteBanqueIconMobile, name: "Transfert vers banque", link: '/transfert/1' },
    { img: PaiementIconManque, name: "Paiement de factures locale", link: '/paimentFacture' },
    { img: iconAssuranceResponsive, name: "Souscription assurance locale", link: '/assurance' },
    { img: BenifIcon, name: 'Bénéficiaires', link: '/beneficiary' },
  ]
  
  return (
    <div className={classNames('hidden lg:block w-full', !open && 'fixed overflow-hidden z-40')}>
      <div className={classNames('  bg-[#5754E5] ', open ? 'h-[80px]' : ' relative h-screen ')}>
        <div
          className={classNames(!open && ' ', `flex items-center justify-between `)}
        >
          <Link to='/' className=" h-[80px] ">
            <img width={150} height={50} src={logoMyEasyMobile} alt="" />
          </Link>
          
          <img
            className={classNames('absolute ', open ? ' right-0' : 'hidden')}
            width={80}
            height={80}
            onClick={() => setOpen(!open)}
            src={Groupe}
            alt=""
          />
          {/* <img src={Bell} alt="" className={classNames('absolute ', open ? 'right-12' : 'hidden')} />
          <img src={Message} alt="" className={classNames('absolute ', open ? 'right-4' : 'hidden')} /> */}
          <img
            className={classNames('absolute ', open ? 'hidden' : ' right-8')}
            width={25}
            height={25}
            onClick={() => setOpen(!open)}
            src={Close}
            alt=""
          />
        </div>

          <div className='h-full py-10'>
            <ul className={classNames(`flex gap-6 ml-5 flex-col `)}>
              {Links.map((v, index: number) => (
                <li
                  key={index}
                  className={ classNames(`text-[#FFFF] hover:text-[#58575b] font-bold text-[22px] sm:text-[20px]`,  open && 'hidden')}
                  onClick={() => setOpen(!open)}
                >
                  <Link to={v.link} className={`flex items-center gap-4`}>
                    <img alt="" src={v.img} />
                    {v.name}
                  </Link>
                </li>
              ))}
              <div
                onClick={() => {
                  setOpenModalLogOut(true)
                }}
                className="flex items-center gap-4 hover:cursor-pointer text-[#FFFF] hover:text-[#58575b]   text-white font-bold text-[22px] sm:text-[20px] "
              >
                <img alt="" src={LogOutIcon} />
                 Déconnexion
              </div>
            </ul>
            <ModalLogOutContainer isOpen={openModalLogOut} closeModal={closeModal} openModalLogOut={openModalLogOut}/>
            { !open && <img className=" right-0 top-1/2  absolute " width={160} height={160} src={Vector} alt="" />}        

          </div>
        
      </div>
    </div>
  )
}
