import React, { useContext } from 'react'
import UserContext from 'contexts/UserContext'
import Thumbs from '../assets/thumbs.svg'
import search from '../assets/search.png'
import Message from '../assets/message.svg'
import Bell from '../assets/bell.svg'
import Modif from '../assets/modif.svg'
import LogoEasy from '../assets/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import logoMyEasy from '../assets/logoMyEasy.svg'
import handlogohome from '../assets/handlogohome.svg'

const Haut: React.FC = () => {
  const { user } = useContext(UserContext)
  const Navigate = useNavigate()
  return (
    <div className="flex pt-12 w-full pl-[40px]  items-center lg:hidden h-[50px] mb-20  ">
      <div className='w-[320px]'>
        <img className="hover:cursor-pointer" src={logoMyEasy} onClick={()=>{Navigate('/')}}/>
      </div>
      <div className="flex items-center">
        <div className="flex items-center gap-[15px]">
          <div className="text-[21px] font-semibold h-[24px]">Bonjour {user?.user?.personalInfo?.firstName}</div>
          <img src={handlogohome} className="w-8 h-8" />
        </div>
      </div>

      {/* <img className="mr-[15px]" src={Bell} /> */}
     
        <div className="flex items-center justify-between w-[300px] pr-5 absolute right-0">
          {/* <div className="flex items-center"> */}
            <div className="text-[21px] sm:text-[15px] xs:text-[15px] font-semibold hover:cursor-pointer"onClick={()=>{Navigate('/settingProfile/0')}}>Mon Profil</div>
            <img src={Modif} alt="" width={40} height={40} className='hover:cursor-pointer ' onClick={()=>{Navigate('/settingProfile/0')}}/>
          {/* </div> */}
        </div>
  
    </div>
  )
}
export default Haut
