import React, { useEffect, useContext, useState } from 'react'
import UserContext from 'contexts/UserContext'
import InputSelect from '../../components/InputSelect'
import { PartnersList } from 'requests/partner'
import { Partner, ListPartner, AddressPartnerInput, PartenaireList } from 'types/Transfert'
import classNames from 'classnames'
import { Spinner } from 'utils/loading'
import swaredLogo from '../../assets/swaredLogo.svg'
import RadioFocus from '../../assets/radioFocus.svg'
import RadioON from '../../assets/radioON.svg'
import { on } from 'events'
import wafaCashLogo from '../../assets/wafaCashLogo.svg'
import banqueTunisieLogo from '../../assets/banqueTunisieLogo.svg'
import PartnerCard from 'containers/pointColectContainer/partnerCard'
import InputSelectVille from 'components/inputSelectVille'
import ListeCarteBancairePage from './listeCarteBancairePage'
import { useNavigate } from 'react-router-dom'
import ModalTestStatusUser from 'components/modalTestStatusUser'
import PointDeCollectMaroc from './pointDeCollectMaroc'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'
import { getListByCity, getListByCountry, getListByGovernorate } from 'requests/transfer'

interface props {
  setColectPointId: (value: string) => void
  setNumStep: (value: number) => void
  idBeneficiary: any
  apiData: any
  colectPointId: any
  setChoisirCarte?: (value: any) => void
  choisirCarte: number
  banqueName: any
  setBanqueName: (value: string) => void
  iban: any
  setIban: (value: string) => void
  setReseaux: (value: string) => void
  setSelectedBnfList: (value: any) => void
  selectedBnfList: any
  bnfidentifiant: any
  setBnfidentifiant: (value: any) => void
  bankcode: any
  setBankcode: (value: any) => void
  swiftBic: any
  setSwiftBic: (value: any) => void
  code?: any
  valuePays: any
  networkCheckedMaroc: number
  setNetworkCheckedMaroc: (value: number) => void
  euroValue: any
  functionTracks: any
  reseaux: string
}
function containsObject(name: string, list: any) {
  if (list.length > 0) {
    var i
    for (i = 0; i < list.length; i++) {
      if (list[i].name === name) {
        return true
      }
    }
  }
  return false
}

const PointCollecte: React.FC<props> = ({
  setColectPointId,
  setNumStep,
  idBeneficiary,
  apiData,
  colectPointId,
  setChoisirCarte,
  choisirCarte,
  banqueName,
  setBanqueName,
  iban,
  setIban,
  setReseaux,
  setSelectedBnfList,
  selectedBnfList,
  bnfidentifiant,
  setBnfidentifiant,
  bankcode,
  setBankcode,
  swiftBic,
  setSwiftBic,
  code,
  valuePays,
  networkCheckedMaroc,
  setNetworkCheckedMaroc,
  euroValue,
  functionTracks,
  reseaux,
}) => {
  const { user } = useContext(UserContext)
  const { TransferData, setTransferData } = useContext(transferBeneficiaryContext)
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [partnersData, setPartnersData] = useState<Partner[]>([])
  const [listGov, setListGov] = useState<AddressPartnerInput[]>([])
  const [valueGov, setValueGov] = useState('')
  const [villes, setVilles] = useState<Partner[]>([])
  const [valueVille, setValueVille] = useState('')
  const [pointColectData, setPointColectData] = useState<Partner[]>([])
  const [openIsModalStatus, setOpenIsModalStatus] = useState(false)
  const [selectedOptionPointCollecte, setSelectedOptionPointCollecte] = useState('')
  const [selectedOptionReseaux, setSelectedOptionReseaux] = useState('')
  //get Governorate
  const [getListGovernorate, setGetListGovernorate] = useState([])
  const [getGovernorate, setGovernorate] = useState('')
  //get City
  const [getListCity, setGetListCity] = useState([])
  const [getCity, setGetCity] = useState('')

  //get partenaire
  const [getListPartenaireByCity, setListPartenaireByCity] = useState([])

  //test status user
  const statusComplete = user?.user?.status === 'confirmed' && user?.user?.isDocumentSent
  const statusPending = user?.user?.status === 'pending' && user?.user?.isDocumentSent
  const statusNotFound = user?.user?.status === 'pending' && !user?.user?.isDocumentSent

  const [previousId, setPreviousId] = useState(null)
  const [saveIdGov, setSaveIdGov] = useState()

  const openModalStatus = () => {
    setOpenIsModalStatus(true)
  }
  const closeModalStatus = () => {
    setOpenIsModalStatus(false)
  }



  // useEffect(() => {
  //   async function fetchData() {
  //     const response = apiData
  //     if (response) {
  //       setPartnersData(response)
  //       const arr: AddressPartnerInput[] = []
  //       response.map((val: any, index: number) => {
  //         if (!containsObject(val.address.governorate, arr)) {
  //           arr.push({ name: val.address.governorate, index, ...val.address })
  //         }
  //       })
  //       setListGov(arr)
  //       setLoading(true)
  //     }
  //   }
  //   fetchData()
  // }, [])

  // useEffect(() => {
  //   if (valueGov !== '') {
  //     var newData: Partner[] = partnersData?.filter(function (Partner) {
  //       return Partner?.address?.governorate === valueGov
  //     })
  //     const newArray: Partner[] = []
  //     newData?.forEach((obj) => {
  //       if (!newArray.some((item) => item.address.city === obj.address.city)) {
  //         newArray.push({ ...obj })
  //       }
  //     })
  //     setVilles(newArray)
  //   }
  // }, [valueGov])

  // useEffect(() => {
  //   if (valueVille !== '') {
  //     var newData: Partner[] = partnersData?.filter(function (Partner) {
  //       return Partner?.address?.city === valueVille
  //     })
  //     setPointColectData(newData)
  //   }
  // }, [valueVille])

  //get list governorate by country
  useEffect(() => {
    async function functionGetListByCountry() {
      const country = valuePays.value === 'TND' ? 'Tunisie' : 'Maroc'
      const amount = euroValue
      try {
        const response = await getListByCountry(country, amount)
        if (response?.success === true) {
          //console.log('response Governorate', response)
          setGetListGovernorate(response?.partners[0]?.governorate || [])
        } else {
          console.error('response failed', response)
        }
        setLoading(true)
      } catch (error) {
        console.error(error)
      }
    }
    functionGetListByCountry()
  }, [])

  //get list city by Governorate
  const functionGetListByGovernorate = async () => {
    const country = valuePays.value === 'TND' ? 'Tunisie' : 'Maroc'
    const amount = euroValue
    const governorate = getGovernorate
    try {
      const response = await getListByGovernorate(country, amount, governorate)
      if (response?.success === true) {
        //console.log('response list city', response)
        setGetListCity(response?.partners[0]?.cities || [])
      } else {
        console.error('response failed', response)
      }
      setLoading(true)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (getGovernorate) {
      functionGetListByGovernorate()
    }
  }, [getGovernorate])

  //get list city by City
  const functionGetListByCity = async () => {
    const country = valuePays.value === 'TND' ? 'Tunisie' : 'Maroc'
    const amount = euroValue
    const governorate = getGovernorate
    const city = getCity
    try {
      const response = await getListByCity(country, amount, governorate, city)
      if (response?.success === true) {
        //console.log('response partenaire', response)
        setListPartenaireByCity(response.partners)
      } else {
        console.error('response failed', response)
      }
      setLoading(true)
    } catch (error) {
      console.error(error)
    }
  }

//getListPartenaireByCity.filter((item: any) => item.network === 'izi' || 'Viamobile')

  useEffect(() => {
    if (getCity) {
      functionGetListByCity()
    }
  }, [getCity])

  const fnChangeValueGov = (value: string) => {
    setValueGov(value)
    setTransferData({ ...TransferData, governorate: value })
    setValueVille('')
  }
  const fnChangeValueVille = (value: string) => {
    setValueVille(value)
    setTransferData({ ...TransferData, ville: value })
    setPointColectData([])
  }

  useEffect(() => {
    if (TransferData) {
      setValueGov(TransferData?.governorate || '')
      setValueVille(TransferData?.ville || '')
    }
  }, [])


  if (!loading) return <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />

  return (
    <div className="">
      {valuePays?.value === 'TND' ? (
        <>
          {choisirCarte === 0 ? (
            <>
              <div className="w-full px-4 h-[400px]">
                <div className="flex flex-col items-center sm:justify-center sm:ml-auto">
                  <div className="w-full">
                    <InputSelect
                      placeholder="Gouvernorat"
                      value={valueGov}
                      setItem={fnChangeValueGov}
                      data={listGov}
                      isObjectData={true}
                      width="w-fill-available"
                      euroValue={euroValue}
                      partnersData={partnersData}
                      isDisabled={listGov?.length > 0 ? false : true}
                      previousId={previousId}
                      setPreviousId={setPreviousId}
                      setSaveIdGov={setSaveIdGov}
                      setReseaux={setReseaux}
                      setSelectedOptionPointCollecte={setSelectedOptionPointCollecte}
                      setSelectedOptionReseaux={setSelectedOptionReseaux}
                      setValueGov={setValueGov}
                      setValueVille={setValueVille}
                      getListGovernorate={getListGovernorate}
                      setGetGovernorate={setGovernorate}
                      functionGetListByGovernorate={functionGetListByGovernorate}
                      getListPartenaireByCity={getListPartenaireByCity}
                      setGetCity={setGetCity}
                      getGovernorate={getGovernorate}
                    />
                  </div>
                  <div className="w-full mt-8">
                    <InputSelectVille
                      value={valueVille}
                      setItem={fnChangeValueVille}
                      data={villes}
                      isObjectData={true}
                      width="w-full"
                      placeholder="Ville"
                      keyOfIndex={'address'}
                      keyOfIndex1={'city'}
                      euroValue={euroValue}
                      setReseaux={setReseaux}
                      partnersData={partnersData}
                      isDisabled={villes?.length > 0 ? false : true}
                      setSelectedOptionPointCollecte={setSelectedOptionPointCollecte}
                      setValueVille={setValueVille}
                      getListCity={getListCity}
                      getListGovernorate={getListGovernorate}
                      setGetCity={setGetCity}
                      getCity={getCity}
                      setSelectedOptionReseaux={setSelectedOptionReseaux}
                    />
                  </div>
                </div>

                <PartnerCard
                  selectedOptionPointCollecte={selectedOptionPointCollecte}
                  setSelectedOptionPointCollecte={setSelectedOptionPointCollecte}
                  tableauNetwork={pointColectData}
                  setColectPointId={setColectPointId}
                  setReseaux={setReseaux}
                  euroValue={euroValue}
                  reseaux={reseaux}
                  previousId={previousId}
                  saveIdGov={saveIdGov}
                  colectPointId={colectPointId}
                  selectedOptionReseaux={selectedOptionReseaux}
                  setSelectedOptionReseaux={setSelectedOptionReseaux}
                  getListPartenaireByCity={getListPartenaireByCity}
                  getListCity={getListCity}
                />

                <div className="flex sm:flex-col-reverse gap-[16px] sm:mt-[3rem] justify-center w-full h-[56px] sm:h-[40px] sm:px-4 2sm:mx-4">
                  <button
                    className={classNames(
                      'text-[18px] text-[#556987] px-[28px] sm:py-4 py-[16px] 2sm:py-[13px] border border-[#556987] w-[180px] sm:w-full font-sans rounded-lg',
                    )}
                    onClick={() => {
                      setNumStep(1)
                    }}
                  >
                    Précédent
                  </button>

                  <button
                    className={classNames(
                      selectedOptionPointCollecte === '' ||
                        selectedOptionPointCollecte === null ||
                        colectPointId === null ||
                        colectPointId === ''
                        ? 'bg-[#A5A5A5] hover:cursor-not-allowed'
                        : 'bg-[#4743C9] hover:cursor-pointer',
                      'text-[18px] text-[#FFF] bg-[#4743C9] hover:cursor-pointer px-[28px] py-[16px] 2sm:py-[13px] sm:py-4 md:my-0 rounded-lg w-[375px] sm:w-full',
                    )}
                    onClick={() => {
                      if (statusComplete) {
                        setNumStep(3)
                        functionTracks('3')
                      } else if (statusPending) {
                        openModalStatus()
                      } else if (statusNotFound) {
                        navigate('/personalinformation')
                      }
                    }}
                    disabled={
                      selectedOptionPointCollecte === '' ||
                      selectedOptionPointCollecte === null ||
                      colectPointId === null ||
                      colectPointId === ''
                        ? true
                        : false
                    }
                  >
                    Suivant
                  </button>
                </div>
                <ModalTestStatusUser
                  isOpen={openIsModalStatus}
                  closeModal={closeModalStatus}
                  statusPending={statusPending}
                  statusNotFound={statusNotFound}
                />
              </div>
            </>
          ) : (
            <ListeCarteBancairePage
              banqueName={banqueName}
              setBanqueName={setBanqueName}
              iban={iban}
              setIban={setIban}
              setNumStep={setNumStep}
              setSelectedBnfList={setSelectedBnfList}
              selectedBnfList={selectedBnfList}
              cinBnf={bnfidentifiant}
              setCinBnf={setBnfidentifiant}
              bankcode={bankcode}
              setBankcode={setBankcode}
              swiftBic={swiftBic}
              setSwiftBic={setSwiftBic}
              functionTracks={functionTracks}
            />
          )}
        </>
      ) : valuePays?.value === 'MAD' ? (
        <PointDeCollectMaroc
          setNumStep={setNumStep}
          networkCheckedMaroc={networkCheckedMaroc}
          setNetworkCheckedMaroc={(value) => setNetworkCheckedMaroc(value)}
          functionTracks={functionTracks}
          setColectPointId={setColectPointId}
          partnersData={partnersData}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
export default PointCollecte
