import Step from 'components/Step'
import StepTransfert from 'components/stepTransfert'
import UserContext from 'contexts/UserContext'
import React, { useContext, useEffect, useState } from 'react'
import Produit from './produit'
import Inscription from './inscription'
import Paiement from './paiement'
import { tracks } from 'requests/transfer'
import { confirmPaymentInssurance } from 'requests/assurance'

export default function assurance() {
  const [loading, setLoading] = useState(false)
  const { user } = useContext(UserContext)

  const nomstep = ['Produit', 'Inscription', 'Paiement']
  const [numStep, setNumStep] = useState(0)

  const [produitAssurance, setProduitAssurance] = useState('')
  const [partenaireAssurance, setPartenaireAssurance] = useState('')
  const [inssuranceId, setInssuranceId] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  // const [linkAssurance, setlinkAssurance] = useState("https://portail.magassur.com.tn/Agences/MRHTRE/MRHTRE.jsp")

  const [trackId, setTrackId] = useState('')

  const functionTracks = async (stepNumber: string) => {
    const id = user?.user?._id
    let dataTracks = {
      label: 'assurance',
      step: stepNumber,
      userId: id,
    }
    if (stepNumber !== '0') {
      const newDataTracks = {
        ...dataTracks,
        trackId: trackId,
      }
      dataTracks = newDataTracks
    }
    try {
      const response = await tracks(dataTracks)
      if (response?.success === true) {
        setTrackId(response?.trackId)
        //console.log('response track assurance:', response)
      } else {
        console.error('response failed', response)
      }
      setLoading(true)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    functionTracks('0')
  }, [])

  const handleConfirmPayment = async () => {
    const payment_id = inssuranceId
    try {
      const response = await confirmPaymentInssurance(payment_id)
      if (response?.success === true) {
        console.log('response Confirm Payment', response)
      } else {
        console.log('Response failed Payment', response)
      }
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div>
      <h1 className="sm:text-[25px] text-center  text-[#1D3A70] text-[35px] font-semibold lg:pt-10">
        Souscription assurance locale
      </h1>  
      <div className="flex justify-center sm:my-5 my-10">
          {nomstep.map((item, index) => (
            <Step key={index} nomStep={item} numStep={index + 1} isActive={index <= numStep} lastStep={index < 2} />
          ))}
      </div>  
 
      <div className=" mx-[15%] 2xl:mx-[4%] sm:mx-5 lg:mt-[50px] ">

        {numStep === 0 && (
          <Produit
            produitAssurance={produitAssurance}
            setProduitAssurance={(value: string) => setProduitAssurance(value)}
            partenaireAssurance={partenaireAssurance}
            setPartenaireAssurance={(value: string) => setPartenaireAssurance(value)}
            setNumStep={setNumStep}
            functionTracks={functionTracks}
          />
        )}

        {numStep === 1 && (
          <Inscription
            setNumStep={setNumStep}
            functionTracks={functionTracks}
            setClientSecret={setClientSecret}
            setInssuranceId={setInssuranceId}
          />
        )}

        {numStep === 2 && (
          <Paiement
            functionTracks={functionTracks}
            handleConfirmPayment={handleConfirmPayment}
            clientSecret={clientSecret}
          />
        )}
      </div>
    </div>
  )
}
