import axios from '../helpers/axios'

export const getAssurance = (userId: any) =>
  axios
    .post(
      `/insurances/maghrebia/url`,
      { userId: userId },
      {
        headers: {
          accept: 'application/json',
        },
      },
    )
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })
export const payedInssurance = (body: any, token: any) =>
  axios
    .post(`/insurances/maghrebia/makePayment`, body, {
      headers: {
        Authorization: token,
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })

export const confirmPaymentInssurance = (payment_id: string | undefined) =>
  axios
    .post(
      `/insurances/maghrebia/confirmPayment`,
      { payment_id },
      {
        headers: {
          accept: 'application/json',
        },
      },
    )
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })
