import React, { useState } from 'react'
import imageDefault from '../../assets/imageDefault.jpeg'
import newDefaultImageFemme from '../../assets/newDefaultImageFemme.svg'
import newDefaultImageHomme from '../../assets/newDefaultImageHomme.svg'
import ModalHistoryTransfert from './modalHistoryTransfert'
import Avatar from 'components/avatar'

interface Props {
  transactionData: any
}

const HistoryTable: React.FC<Props> = ({ transactionData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [status, setStatus] = useState<string>('complete')
  const [statusCanceled, setStatusCanceled] = useState<string>('canceled')
  const [statusInProgress, setStatusInProgress] = useState<string>('in progress')

  const [modalHistoryTransfert, setModalHistoryTransfert] = useState(false)
  const [indexTransaction, setIndexTransaction] = useState(-1)

  const closeModalHistoryTransfert = () => {
    setModalHistoryTransfert(false)
    setIndexTransaction(-1)
  }

  function formatDate(originalDate: any) {
    const dateObj = new Date(originalDate)
    const jour = dateObj.getDate()
    const mois = dateObj.getMonth() + 1
    const annee = dateObj.getFullYear()
    const jourFormatte = jour < 10 ? `0${jour}` : jour
    const moisFormatte = mois < 10 ? `0${mois}` : mois
    return `${jourFormatte}/${moisFormatte}/${annee}`
  }

  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
    setIndexTransaction(-1)
  }

  const [clickedRows, setClickedRows] = useState<boolean[]>([])

  const handleInputClick = (index: number) => {
    const newClickedRows = [...clickedRows]
    newClickedRows[index] = !newClickedRows[index]
    setClickedRows(newClickedRows)
  }

  return (
    <div className="mx-auto">
      <div className="table-container">
        <table className="table-auto w-full  text-left">
          <thead>
            <tr className='botder-b  sm:border-none font-semibold text-[13px] text-[#939393] 2xl:text-center lg:text-left'>
              <th className="py-2"> Bénéficiaire</th>
              <th className="sm:hidden"> Référence</th>
              <th>Montant envoyé</th>
              <th className="sm:hidden">Date</th>
              <th className="sm:hidden">Points de collecte</th>
              <th>Statut</th>
            </tr>
          </thead>
          {transactionData &&
            transactionData.map((listTransaction: any, index: number) => (
              <React.Fragment key={index}>
                <tbody
                  key={index}
                  //onClick={() => setIndexTransaction(index)}
                >
                  <tr className="hover:bg-gray-100 hover:cursor-pointer border-b text-[#22242C] text-[14px] font-semibold">
                    <td className={` py-2`} onClick={() => handleInputClick(index)}>
                      <div className="flex items-center 2xl:pr-1">
                        
                        <Avatar avatar={listTransaction?.recipient?.avatar} styleCss='w-[34px] h-[34px]' />
                        <div className="pl-2 sm:pr-2 flex flex-col ">
                            <span>
                              {listTransaction?.recipient?.personalInfo?.firstName}{' '}
                              {listTransaction?.recipient?.personalInfo?.lastName}
                            </span>
                  
                            <span className='text-[#8E8EA1] text-[11px] sm:text-[10px] lg:text-[9px] font-normal'>
                              {listTransaction?.recipient?.personalInfo?.phone?.prefix.startsWith('+')
                              ? listTransaction?.recipient?.personalInfo?.phone?.prefix
                              : `${'+'}${listTransaction?.recipient?.personalInfo?.phone?.prefix}`
}
                              {listTransaction?.recipient?.personalInfo?.phone?.number}
                            </span>

                        </div>
                      </div>
                    </td>

                    <td className=" sm:hidden">
                        {listTransaction?.partnerCode}
                    
                    </td>
                    <td className=" pl-2">
                        {listTransaction?.amount?.amountToSend} {listTransaction?.amount?.departureCurrency?.avatar}
                    </td>
                    <td className="  sm:hidden">
                        {formatDate(listTransaction?.createdAt)}
                    </td>

                    <td className="pl-2 sm:hidden">
                        {listTransaction?.partner?.storeInfo?.name &&
                        listTransaction.partner?.storeInfo?.name?.length > 18
                          ? `${listTransaction.partner?.storeInfo?.name?.slice(0, 18)}...`
                          : listTransaction.partner?.storeInfo?.name}
                    </td>
                    

                    <td className=" w-auto lg:w-[60px]">
                      {status === listTransaction?.status ? (
                        <div className="bg-[#EBFFF3] text-[#20C374]  flex items-center justify-center w-auto p-2 sm:p-[5px] text-[14px] sm:text-[12px]  rounded-lg">
                          Terminé
                        </div>
                      ) : statusInProgress === listTransaction?.status ? (
                        <div className="bg-[#FFDECC] text-[#FF5C00] text-center py-2 flex items-center justify-center w-auto 2md:h-[22px] text-[14px] sm:text-[12px] rounded-lg">
                          En cours
                        </div>
                      ) : statusCanceled === listTransaction?.status ? (
                        <div className="bg-[#FED7D8] text-[#EC1C24] flex items-center justify-center w-auto p-2 h-[35px] 2md:h-[22px] text-[14px] sm:text-[12px] rounded-lg">
                          Annulé
                        </div>
                      ) : (
                        <></>
                      )}
                    </td>
                    {/* <td className="border-b px-4 py-2">
                      <div className="flex ml-4 sm:ml-0">
                        <img
                          src={iconPrint}
                          className="w-5 h-5 mr-5 cursor-pointer sm:hidden"
                          onClick={() => {
                            setIndexTransaction(index)
                            //openModal()
                          }}
                        />
                      </div>
                    </td> */}
                  </tr>
                  {/* <ModalPrintHistory isOpen={index === indexTransaction} closeModal={closeModal} list={listTransaction} /> */}
                </tbody>
                {/* <ModalHistoryTransfert
                  isOpen={index === indexTransaction}
                  closeModal={closeModalHistoryTransfert}
                  beneficiaire={`${listTransaction?.recipient?.personalInfo?.firstName} ${' '} ${listTransaction
                    ?.recipient?.personalInfo?.lastName}`}
                  montantEnvoye={listTransaction?.amount?.amountToSend}
                  pointsCollecte={listTransaction?.partner?.storeInfo?.name}
                  date={formatDate(listTransaction?.createdAt)}
                  distributionChannel={listTransaction?.distributionChannel}
                /> */}
              </React.Fragment>
            ))}
        </table>
      </div>
    </div>
  )
}

export default HistoryTable
