import React, { useEffect } from 'react'
import { memo, useState } from 'react'
import UserContext from '../contexts/UserContext'
import TransfertContext from 'contexts/TransfertContext'
import { UserLog } from 'types/User'
import startup from './Startup'
import BeneficiaryContext from 'contexts/beneficiaryContext'
import { Beneficiary } from 'types/beneficiary'
import RechargeContext from 'contexts/rechargeContext'
import { Recharge } from 'types/recharge'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'
import { TransferBeneficiary } from 'types/transferBeneficiary'
import paimentFactureContext from 'contexts/paimentFactureContext'
import { PaimentFacture } from 'types/paimentFacture'

const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState(null as UserLog | null)
  const [beneficiaries, setBeneficiaries] = useState(null as Beneficiary | null)
  const [recharges, setRecharges] = useState(null as Recharge | null)
  const [TransferData, setTransferData ] = useState(null as TransferBeneficiary | null)
  const [TransferDataBeneficiary, setTransferDataBeneficiary ] = useState(null as TransferBeneficiary[] | null)
  const [paimentFacture, setPaimentFacture ] = useState(null as PaimentFacture | null)

  const [startupEnded, setStartupEnded] = useState(false)
  const [mantantToSend, setMantantToSend] = useState(100)
  const [mantantToRecu, setMantantToRecu] = useState(0)
  const [taux, setTaux] = useState(0)
  const [commision, setCommision] = useState(0)

  useEffect(() => {
    startup().then((u) => {
      setUser(u)
      setStartupEnded(true)
    })
  }, [])
  if (!startupEnded) return <div />

  return (
    <UserContext.Provider 
    value={{
      user,
      setUser
    }}
    >
      <RechargeContext.Provider 
        value={{
            recharges,
            setRecharges
        }}
      >
      <BeneficiaryContext.Provider
       value={{
        beneficiaries,
        setBeneficiaries
       }}
      >
      <TransfertContext.Provider
        value={{
          mantantToSend,
          setMantantToSend,
          mantantToRecu,
          setMantantToRecu,
          taux,
          setTaux,
          commision,
          setCommision,
        }}
      >
        <transferBeneficiaryContext.Provider 
          value={{
            TransferData,
            setTransferData,
            TransferDataBeneficiary,
            setTransferDataBeneficiary
          }}
        >
        <paimentFactureContext.Provider 
          value={{
            paimentFacture,
            setPaimentFacture
          }}
        >
        {children}
        </paimentFactureContext.Provider>
        </transferBeneficiaryContext.Provider>
      </TransfertContext.Provider>
      </BeneficiaryContext.Provider>
      </RechargeContext.Provider>
    </UserContext.Provider>
   
  )
}

export default memo(AuthProvider)
