import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import rechargeMobileIcon from '../../assets/rechargeMobileIcon.svg'

interface Props {
  isOpen: boolean
  closeModal: () => void
  successPayment: string
  isInvoices?: boolean
  isAssurance?: boolean
}

const SuccessPaymentModal: React.FC<Props> = ({ isOpen, closeModal, successPayment, isInvoices, isAssurance }) => {
  if (!isOpen) return null
  const navigate = useNavigate()
  return (
    <div>
      <div
        className={classNames('justify-center items-center flex fixed inset-0 z-50 bg-[#4945c12f] backdrop-blur-sm')}
      >
        <div className="relative my-6 mx-auto max-w-2xl">
          <div
            className={classNames(
              'border-0 rounded-xl pb-8 pt-5 px-[10rem] sm:px-8 md:px-8 sm:py-8 md:py-8 shadow-xl relative xl:py-4 flex flex-col w-full bg-white',
            )}
          >
            <div className="flex flex-col justify-center items-center mt-10">
              <div className="text-[#1D3A70] text-[25px] sm:text-[20px] md:text[20px] font-bold pb-1 text-center">
                {successPayment === 'succeeded' ? (
                  <div className="flex flex-col items-center justify-center">
                    {isInvoices ? (
                      <div>
                        <div className="mt-5">Paiement effectué avec succès.</div>
                      </div>
                    ) : isAssurance ? (
                      <div className="flex flex-col items-center justify-center">
                        <div>
                          <div className="mt-5">Assurance envoyée avec succès.</div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <img src={rechargeMobileIcon} className="w-[150px] h-[150px] object-contain" />
                        <div className="mt-5">Recharge mobile envoyée avec succès.</div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center">
                    {isInvoices ? (
                      <div className="mt-5">Payment n’a pas été envoyée.</div>
                    ) : isAssurance ? (
                      <div className="flex flex-col items-center justify-center">
                        <div>
                          <div className="mt-5">Assurance n’a pas été envoyée.</div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <img src={rechargeMobileIcon} className="w-[150px] h-[150px] object-contain" />
                        <div className="mt-5">Votre recharge mobile n’a pas été envoyée.</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <button
                onClick={() => {
                  closeModal()
                  navigate('/')
                }}
                className={`rounded-xl h-[50px] mt-8 text-white text-[15px] w-full font-medium bg-blue-500
                ${successPayment === 'succeeded' ? 'bg-blue-500' : 'bg-red-500'}
                `}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessPaymentModal
