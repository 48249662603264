import React from 'react'
import Retour from '../../assets/flechRetour.svg'

interface Props{
    image: string
    retour?: boolean
}


const LogoConatiner : React.FC<Props>  = ({image, retour=true}) =>{
    const handleLink = ()=>{
        window.location.href = 'https://app-dev.myeasytransfer.com/login'
    
    }
return(
    
        <div className="mx-[40px] lg:hidden flex justify-between sm:mt-0 mt-[50px]">
          <img className='cursor-pointer' width={160} height={50} src={image} alt="logo My Easy transfer" onClick={handleLink} />
            {retour && (<div onClick={handleLink} className='p-2 flex items-center gap-4  border-[2px] border-[#F1F4F9] rounded-xl text-[#5754E5]  cursor-pointer'>
                <img src={Retour} alt='Retour' width={8} height={10}/>
                    Retour
                </div>)
            }
        </div>
    
)
}

export default LogoConatiner