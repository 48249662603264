import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import iconCloseModalRecharge from '../../assets/iconCloseModalRecharge.svg'
import btnDeleted from '../../assets/btnDeleted.svg'
import UserContext from 'contexts/UserContext'
import { deleteBeneficary } from 'requests/benificary'
interface Props {
  isOpen: boolean
  closeModal: () => void
  idBnf: any
  isModalOpenDelete: boolean
  setBeneficiaryData:any
  isAdded?: boolean
  setIsAdded:()=>void 
}

const ModalDeleteBeneficiary: React.FC<Props> = ({ isOpen, closeModal, idBnf, isModalOpenDelete, setBeneficiaryData, isAdded, setIsAdded, }) => {
  if (!isOpen) return null
  const { user } = useContext(UserContext)

  const deleteBeneficiaryFunction = async () => {
    const id = user?.user?._id
    const token = user?.token?.accessToken
    try {
       const response = await deleteBeneficary(id, token, idBnf)
       if (response?.success) {
         if (setIsAdded) {
          setIsAdded()
        }
       }
      closeModal()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      {isModalOpenDelete && (
        <div
          className={classNames(
            'fixed flex flex-col justify-center place-items-center inset-0 z-50 bg-[#4945c12f] backdrop-blur-sm',
          )}
        >
           <img
                className=" w-24 h-24 -mb-10 sm:-mb-8 object-cover rounded-full shadow-xl border-white border-[5px]"
                src={btnDeleted}
                alt="Person Image"
              />
          <div
            className={classNames(
              'rounded-xl w-[373px] sm:w-[320px] bg-image-bg-beneficiary bg-cover bg-no-repeat',
            )}
          >
            <div className="p-5 flex flex-col text-center">
              <div className="font-bold text-[19px] text-[#1D3A70] mt-8">Confirmez-vous la suppression</div>
              <div className="text-[14px] font-normal text-[#6B7280] mt-5">
                Si vous confirmez, votre bénéficiaire sera définitivement effacé.
              </div>
              <div className="flex justify-around mt-8">
                <button
                  className="text-white text-[16px] font-semibold bg-[#4743C9] p-5 rounded-lg"
                  onClick={deleteBeneficiaryFunction}
                >
                  Je confirme
                </button>
                <button className="text-white text-[16px] font-semibold bg-[#F00] p-5 rounded-lg" onClick={closeModal}>
                  Annuler
                </button>
              </div>
            </div>
          </div>

          {/* <div className="absolute top-[31%] transform -translate-x-1/2 -translate-y-1/2 left-1/2"> */}
             
          {/* </div> */}
        </div>
      )}
    </div>
  )
}

export default ModalDeleteBeneficiary
