import React from 'react'
import {useNavigate } from 'react-router-dom'
import logoTransferPaiment from '../../assets/logoTransferPaiment.svg'
import logoTransferWallet from '../../assets/logoTransferWallet.svg'
import logoTransfer from '../../assets/logoTransfer.svg'
import logoRecharge from '../../assets/logoRecharge.svg'
import FlecheBlue from '../../assets/flechebleu.svg'
import assuranceImage from '../../assets/assuranceImage.svg'

const homeBlockLinks: React.FC = () => {
  const list: { image: string; linkTo: string; text: string }[] = [
    { image: logoTransfer, linkTo: '/transfert/0', text: "Transfert d'argent avec retrait en cash" },
    { image: logoRecharge, linkTo: '/recharge', text: 'Recharge mobile' },
    { image: logoTransferWallet, linkTo: '/transfert/1', text: "Transfert d'argent vers un compte bancaire" },
    { image: logoTransferPaiment, linkTo: '/paimentFacture', text: 'Paiement de factures locales' },
    { image: assuranceImage, linkTo: '/assurance', text: 'Souscription assurance locale' },
  ]

  const navigate = useNavigate()
  return (
    <div className="grid grid-cols-2 gap-[6%] lg:gap-5  items-center ">
      {list.map((item, index) => (

        <div
          key={index}
          onClick={() => { navigate(item?.linkTo)}}

          className={`flex h-[100px] justify-between items-center relative  shadow-lg pl-3 sm:h-[150px] sm:pl-2  rounded-xl  
          ${item.text === 'Souscription assurance locale' || item.text === 'Paiement de factures locales' ? 'bg-[#d5d5d5] cursor-not-allowed' : 'bg-[#f2f2f2] cursor-pointer'}
        `}
        >
          <div className={`flex items-center sm:flex-col sm:items-start `}
          >
            <img className='w-[58px] h-[58px] sm:w-[40px] sm:h-[45px]'  src={item.image} alt={item.text}
            />
           
            <div className='font-bold ml-3 sm:ml-0 lg:h-[55px] w-[200px] sm:w-full'>
              {item.text}
            </div>
          </div>

          <img className={`3xl:hidden absolute right-4`} src={FlecheBlue}/>
         
        </div>
      ))}
    </div>
  )
}

export default homeBlockLinks
