import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import drapeauTunisie from '../../assets/drapeauTunisie.svg'
import drapeauMaroc from '../../assets/drapeauMaroc.svg'

import RadioFocus from '../../assets/radioFocus.svg'
import RadioON from '../../assets/radioON.svg'
import imagePaiementSonede from '../../assets/imagePaiementSonede.svg'
import imagePaiementSteg from '../../assets/imagePaiementSteg.svg'
import imagePaiementTopnet from '../../assets/imagePaiementTopnet.svg'
import CustomSelect from 'components/CustomSelect'
import paimentFactureContext from 'contexts/paimentFactureContext'
import InfoLogo from '../../assets/infoIcon.svg'
import StegInfoIcon from '../../assets/steginfo.svg'
import SonedInfoIcon from '../../assets/sonedinfo.svg'
import Modal from 'components/Modal'

interface Props {
  onChangeStep: any
  pays: any
  setPays: (value: string) => void
  onChange: (value: string) => void
  setOperateur: (value: string) => void
  numeroADSL: string
  setNumeroADSL: (value: string) => void
  isOperateur: string
  paiementFactureUser: any
  billerCode: any
  facture: any
  setBillerCode: (value: string) => void
  objectReference: any
  setObjectReference: (value: string) => void
  functionTracks: any
  stegNumber: string
  setStegNumber: (value: string) => void
  sonedNumero: string
  setSonedNumero: (value: string) => void
}

const Choix: React.FC<Props> = ({
  onChangeStep,
  pays,
  setPays,
  onChange,
  isOperateur,
  setOperateur,
  numeroADSL,
  setNumeroADSL,
  paiementFactureUser,
  billerCode,
  setBillerCode,
  facture,
  objectReference,
  setObjectReference,
  functionTracks,
  stegNumber,
  setStegNumber,
  sonedNumero,
  setSonedNumero,
}) => {
  const { paimentFacture, setPaimentFacture } = useContext(paimentFactureContext)

  const [selectedValue, setSelectedValue] = useState<string>('Tunisie')
  const [isClicked, setIsClicked] = useState(false)

  const [isValid, setIsValid] = useState(true)
  const [isValidFacture, setIsValidFacture] = useState(true)
  const [isValidFactureSteg, setIsValidFactureSteg] = useState(true)
  const [openModalGuide, setOpenModalGuide] = useState(false)
  const handleSelectChange = (selectedValue: string) => {
    setSelectedValue(selectedValue)
    // setPays(selectedValue)
  }
  const handleClick = () => {
    setIsClicked(!isClicked)
  }

  const handleInputChange = (event: any) => {
    const inputValueWithoutSpaces = event.target.value.replace(/\D/g, '').slice(0, 9)
    let isValidInput = inputValueWithoutSpaces
    isValidInput = /^[0-9]{8}$/.test(inputValueWithoutSpaces)
    setNumeroADSL(inputValueWithoutSpaces)
    setPaimentFacture({ ...paimentFacture, numeroADSL: inputValueWithoutSpaces })
    setIsValid(isValidInput)
  }

  const handleInputChangeFacture = (e: any) => {
    const inputValueWithoutSpaces = e.target.value.replace(/\D/g, '').slice(0, 9)
    setSonedNumero(inputValueWithoutSpaces)
    const isValidFacture = /^[0-9]{8}$/.test(inputValueWithoutSpaces)
    setPaimentFacture({ ...paimentFacture, objectReference: inputValueWithoutSpaces })
    setIsValidFacture(isValidFacture)
  }

  const handleInputChangeFactureSteg = (e: any) => {
    const inputValueWithoutSpaces = e.target.value.replace(/\D/g, '').slice(0, 9)
    setStegNumber(inputValueWithoutSpaces)
    const isValidFacture = /^[0-9]{9}$/.test(inputValueWithoutSpaces)
    setPaimentFacture({ ...paimentFacture, stegNumber: inputValueWithoutSpaces })
    //setObjectReference(inputValueWithoutSpaces)
    setIsValidFactureSteg(isValidFacture)
  }

  useEffect(() => {
    if (paimentFacture) {
      setNumeroADSL(paimentFacture?.numeroADSL || '')
      setSonedNumero(paimentFacture?.objectReference || '')
      setStegNumber(paimentFacture?.stegNumber || '')
      onChange(paimentFacture?.isChoisiroperator || '')
    }
  }, [])
  // useEffect(()=>{
  //   if(paimentFacture?.isChoisiroperator === 'Topnet'){
  //     setPaimentFacture({ ...paimentFacture, stegNumber: '' , objectReference:'' })
  //     // setSonedNumero('')
  //     // setStegNumber('')
  //   }else if(paimentFacture?.isChoisiroperator === 'Soned'){
  //     setPaimentFacture({ ...paimentFacture, stegNumber: '' , numeroADSL: '' })
  //     // setNumeroADSL('')
  //     // setStegNumber('')
  //   } else{
  //     setPaimentFacture({ ...paimentFacture, objectReference: '' , numeroADSL:'' })
  //     // setNumeroADSL('')
  //     // setSonedNumero('')
  //   }
  // },[paimentFacture?.isChoisiroperator])

  const options: { icon: any; value: string; label: string }[] = [
    { icon: drapeauTunisie, value: 'Tunisie', label: 'Tunisie' },
    { icon: drapeauMaroc, value: 'Maroc', label: 'Maroc' },

  ]
  return (
    <div>
      <div className='flex flex-col gap-6 sm:gap-4'>
      {/* <div className="md:my-[30px] flex flex-col mt-8 h-[330px] mx-[50px] 2sm:mr-auto 2sm:ml-6 sm:mx-auto"> */}
        <div className="text-[#1D3A70] text-[20px] font-bold">
          Pays de destination
          {/* <span className="text-[#FF0000]">*</span>{' '} */}
        </div>
        {/* <div className="h-[70px] mt-[25px]">
          <div className="flex flex-col gap-[10px] 2sm:w-full"> */}
            <CustomSelect
              options={options}
              value={options.find((item) => item.value === selectedValue)?.label || 'Pays'}
              onChange={handleSelectChange}
              placeholder=""
              icon={options.find((item) => item.value === selectedValue)?.icon}
              disableMaroc= {true}
              marginRightIcon='mr-5'
              height="h-[50px]"
              background="bg-gray-100 rounded-xl"
              textSize="text-[15px] sm:text-[12px]"
              heightDropdown=""
              roundedIcon="rounded-[0px]"
              showArrow={true}
            />
          {/* </div>
        </div> */}
        <div className="text-[#1D3A70] text-[20px] font-bold ">
          Sélectionnez l’opérateur <span className="text-[#FF0000]">*</span>{' '}
        </div>
        <div
          className={`flex gap-6 md:text-center lg:flex-row justify-center ${
            selectedValue === 'Tunisie' ? 'block' : 'hidden'
          }`}
        >
          {/* <div className="flex gap-[33px] 2sm:flex-row sm:flex-row md:text-center md:flex-row lg:flex-row xl:flex-row"> */}
            <div className="flex flex-col items-center hover:cursor-pointer"
             onClick={() => {
              setOperateur('TP')
              onChange('Topnet')
              setBillerCode('s600')
              setPaimentFacture({ ...paimentFacture, isChoisiroperator: 'Topnet' })
            }}>
              <img
                src={imagePaiementTopnet}
                width={200}
                height={133}
               
                className="rounded-md sm:w-[180px] sm:h-[80px] object-cover"
              />

              <div onClick={handleClick} className="relative mt-3 items-center">
                <img
                  src={RadioFocus}
                  className="w-6 h-6"
                />
                {isOperateur === 'Topnet' && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
              </div>
            </div>

            <div className="flex flex-col items-center hover:cursor-pointer"
              onClick={() => {
                setOperateur('SD'), onChange('Soned')
                setBillerCode('s502')
                setPaimentFacture({ ...paimentFacture, isChoisiroperator: 'Soned' })
              }}
            >
              <img
                src={imagePaiementSonede}
                width={200}
                height={133}
                className="rounded-md sm:w-[180px] sm:h-[80px] object-cover"
              />
              <div onClick={handleClick} className="relative mt-3 items-center">
                <img
                  src={RadioFocus}
                  className="w-6 h-6"
                />
                {isOperateur === 'Soned' && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
              </div>
            </div>

            <div className="flex flex-col items-center hover:cursor-pointer"
              onClick={() => {
                setOperateur('ST'), onChange('Steg')
                setBillerCode('s701')
                setPaimentFacture({ ...paimentFacture, isChoisiroperator: 'Steg' })
              }}
            >
              <img
                src={imagePaiementSteg}
                width={200}
                height={133}
                className="rounded-md sm:w-[180px] sm:h-[80px] object-cover"
                
              />
              <div onClick={handleClick} className="relative mt-3 items-center">
                <img
                  src={RadioFocus}
                  className="w-6 h-6"

                />
                {isOperateur === 'Steg' && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
              </div>
            </div>
          {/* </div> */}
        </div>

        <div className="text-[#1D3A70] flex items-center text-[20px] font-bold  ">
          {(isOperateur === 'Topnet' || isOperateur === 'Soned' || isOperateur === 'Steg') && 'Numéro de contrat'}
          <span
            className={`text-red-500 ml-1 ${
              isOperateur === 'Topnet' || isOperateur === 'Steg' || isOperateur === 'Soned' ? 'block' : 'hidden'
            }`}
          >
            *
          </span>
          {(isOperateur === 'Steg' || isOperateur === 'Soned') && (
            <div
              className="pt-2 pl-4 hover:cursor-pointer w-12 h-6 "
              onClick={() => {
                setOpenModalGuide(true)
              }}
            >
              <img src={InfoLogo} />
            </div>
          )}
        </div>
        {isOperateur === 'Soned' ? (
         
            <div className="flex flex-col gap-2 h-[50px] ">
              <input
                className=" bg-gray-100 border-1 w-full text-[15px]  sm:text-[12px] sm:h-[45px] h-[56px] placeholder-[#1D3A70] text-[#1D3A70] rounded-xl p-5"
                type="text"
                value={sonedNumero}
                placeholder={sonedNumero || 'Numéro de contrat'}
                name="sonedNumero"
                autoComplete="off"
                onChange={handleInputChangeFacture}
              />
              {isOperateur === 'Soned' && sonedNumero.length > 0 && sonedNumero.length !== 8 && (
                <p className="text-red-500 text-[12px]">Ce champ doit comporter 8 chiffres</p>
              )}
            </div>
         
        ) : isOperateur === 'Steg' ? (
          
            <div className="flex flex-col gap-[10px] h-[50px] ">
              <input
                className=" bg-gray-100 border-1 w-full text-[15px]  sm:text-[12px] sm:h-[45px] h-[56px] placeholder-[#1D3A70] text-[#1D3A70] rounded-xl p-5"
                type="text"
                value={stegNumber}
                placeholder={stegNumber || 'Numéro de contrat'}
                name="stegNumber"
                autoComplete="off"
                maxLength={9}
                onChange={handleInputChangeFactureSteg}
              />
              {isOperateur === 'Steg' && stegNumber.length > 0 && stegNumber.length !== 9 && (
                <p className="text-red-500 text-[12px]">Ce champ doit comporter 9 chiffres</p>
              )}
            </div>
          
        ) : (
          

            <div
            
              className={`flex flex-col gap-2 h-[50px] 
              ${isOperateur === 'Topnet' ? 'block' : 'hidden'}`}
            >
              <input
                className=" bg-gray-100 border-1 w-full text-[15px]  sm:text-[12px] sm:h-[45px] h-[56px] placeholder-[#1D3A70] text-[#1D3A70] rounded-xl p-5"
                type="text"
                value={numeroADSL}
                placeholder={numeroADSL || 'Numéro de contrat'}
                name="numeroADSL"
                autoComplete="off"
                maxLength={8}
                onChange={handleInputChange}
              />
              {isOperateur === 'Topnet' && numeroADSL.length > 0 && numeroADSL.length !== 8 && (
                <p className="text-red-500 text-[12px]">Ce champ doit comporter 8 chiffres</p>
              )}
            </div>
          
        )}

        <div
          className={`flex gap-[16px] justify-center my-5 sm:my-1 xs:my-1 place-self-center  sm:w-[100%] xs:w-[100%] lg:w-[50%]`}
        >
          <button
            disabled={
              isOperateur === '' ||
              isOperateur === 'null' ||
              (isOperateur === 'Soned' && (!sonedNumero || sonedNumero.length !== 8)) ||
              (isOperateur === 'Steg' && (!stegNumber || stegNumber.length !== 9)) ||
              (isOperateur === 'Topnet' && (!numeroADSL || numeroADSL.length !== 8))
            }
            className={classNames(
              `text-[18px] text-[#FFF] w-[357px] p-2 h-[56px] sm:mt-[15px] items-center rounded-xl sm:w-[80%]`,
              isOperateur === '' ||
                isOperateur === 'null' ||
                (isOperateur === 'Soned' && (!sonedNumero || sonedNumero.length !== 8)) ||
                (isOperateur === 'Steg' && (!stegNumber || stegNumber.length !== 9)) ||
                (isOperateur === 'Topnet' && (!numeroADSL || numeroADSL.length !== 8))
                ? // ||
                  // !isValid ||
                  // selectedValue === ''
                  'bg-gray-400'
                : 'bg-[#4743C9]',
            )}
            onClick={() => {
              onChangeStep()
              functionTracks('1')
              paiementFactureUser()
            }}
          >
            Suivant
          </button>
        </div>
      </div>
      <Modal isOpen={openModalGuide} onClose={() => setOpenModalGuide(false)}>
        <div className="w-full flex flex-col items-center text-center">
          <span className="mb-5 text-xl font-medium text-gray-400">GUIDE</span>
          <img src={isOperateur === 'Soned' ? SonedInfoIcon : StegInfoIcon} alt="Steg Info Icon" />
          <span className="mb-5 text-lg font-medium text-gray-400 mt-4 ">Où trouver le numéro de contrat ?</span>
          <span className="mb-5 text-sm font-medium text-gray-400">
            Vous trouverez le numéro de contrat sur votre facture, comme indiqué ci-dessus. Assurez-vous de le saisir
            correctement
          </span>
        </div>
      </Modal>
    </div>
  )
}

export default Choix
