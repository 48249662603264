/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext } from 'react'
import { TransferBeneficiary } from 'types/transferBeneficiary'

interface TransferBeneficiaryProps {
  TransferData: TransferBeneficiary | null
  setTransferData: (TransferData: TransferBeneficiary | null) => void
  setTransferDataBeneficiary: (TransferData: TransferBeneficiary[] | null) => void
  TransferDataBeneficiary: TransferBeneficiary[] | null
}

const transferBeneficiaryContext = createContext<TransferBeneficiaryProps>({
  TransferData: null,
  setTransferData: () => {},
  setTransferDataBeneficiary: () => {},
  TransferDataBeneficiary: null,
})

export default transferBeneficiaryContext
