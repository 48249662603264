import React, { useContext } from 'react'

import RadioON from '../../assets/radioON.svg'
import RadioFocus from '../../assets/radioFocus.svg'

import firstImageMaroc from '../../assets/firstImageMaroc.svg'
import secondImageMaroc from '../../assets/secondImageMaroc.svg'
import thirdImageMaroc from '../../assets/thirdImageMaroc.svg'
import RechargeContext from 'contexts/rechargeContext'

interface props {
  setOperateur: (value: string) => void
  onChange: (value: string) => void
  onChangePrefix: (value: string) => void
  phoneNumber: any
  handleClick: any
  isOperateur: string
  isClicked: boolean
}

const MarocCarte: React.FC<props> = ({
  setOperateur,
  onChange,
  handleClick,
  isOperateur,
  onChangePrefix,
  phoneNumber,
  isClicked,
}) => {
  const { recharges, setRecharges } = useContext(RechargeContext)

  return (
    <div>
      
        <div className='flex mt-[47px] 2xl:mx-6 sm:mx-3  gap-6 md:text-center justify-center'>
                 
          <div  className="flex flex-col items-center hover:cursor-pointer"
          onClick={() => {
            setOperateur('INMA')
            onChange('Inwi')
            setRecharges({ ...recharges,isOperateur: 'Inwi', MontantDinar: '',MontantEur:'',MontantTnd:'',isOn:false,indexRechargeTN:-1 })

          }}>
            
             <img
                  src={firstImageMaroc}
                  width={200}
                  height={133}
                  className="rounded-md sm:w-[180px] sm:h-[80px] object-cover"
                  />

            <div className="relative mt-3 items-center">
              <img
                src={RadioFocus}
                className="w-6 h-6"
               
              />
              {(isOperateur === 'Inwi') && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
            </div>
          </div>

          <div  className="flex flex-col items-center hover:cursor-pointer"
            onClick={() => {
              setOperateur('ORMA')
              onChange('OrangeMaroc')
              setRecharges({ ...recharges,isOperateur: 'OrangeMaroc', MontantDinar: '',MontantEur:'',MontantTnd:'',isOn:false,indexRechargeTN:-1 })
            }}
          >
            <img
              src={secondImageMaroc}
              width={200}
              height={133}
              className="rounded-md sm:w-[180px] sm:h-[80px] object-cover"
                  
            />

            <div className="relative mt-3 items-center">
              <img
                src={RadioFocus}
                className="w-6 h-6"
                
              />
              {isOperateur === 'OrangeMaroc' && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
            </div>
          </div>

          <div  className="flex flex-col items-center hover:cursor-pointer"
            onClick={() => {
              setOperateur('MOMA')
              onChange('TTMaroc')
              onChangePrefix(phoneNumber)
              setRecharges({ ...recharges,isOperateur: 'TTMaroc', MontantDinar: '',MontantEur:'',MontantTnd:'',isOn:false,indexRechargeTN:-1 })
            }}>
            <img
              src={thirdImageMaroc}
              width={200}
              height={133}
              className="rounded-md sm:w-[180px] sm:h-[80px] object-cover"
              />

            <div className="relative mt-3 items-center">
              <img
                src={RadioFocus}
                className="w-6 h-6"
                
              />
              {isOperateur === 'TTMaroc' && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
            </div>
          </div>
        </div>
    </div>
  )
}

export default MarocCarte
