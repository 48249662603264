import React, { useState, useContext, useEffect } from 'react'
import UserContext from '../contexts/UserContext'
import { useFormik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import { Login } from 'helpers/api'
import localforage from 'localforage'
import * as yup from 'yup'
import classNames from 'classnames'
import VisibleEye from '../assets/visibleEye.svg'
import InvisibleEye from '../assets/invisibleEye.svg'
import handlogohome from '../assets/handlogohome.svg'
import logoSignup from '../assets/logoSignup.svg'
import LogoConatiner from './signUpContainer/logoContainer'
import ImageSignUp from './signUpContainer/imageSignUp'
import imageLoginMobile from '../assets/imageLoginMobile.svg'
import imageLoginWeb from '../assets/imageLoginWeb.svg'
import newImageLogin from '../assets/newImageLogin.svg'

const LoginContainer: React.FC = () => {
  const { setUser } = useContext(UserContext)
  const navigate = useNavigate()
  const [hasError, setHasError] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      saveEmail: false,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Votre adresse e-mail doit être valide (exemple@domain.com)')
        .required('Ce champ est requis'),
      password: yup
        .string()
        .required('Ce champ est requis')
        .matches(
          /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[&,$,?,+!,#,%(),\-_<>=@[\]^|}{~]).{8,16}$/,
          'Le mot de passe doit contenir au moins 8 caractères avec au moins 1 lettre, 1 chiffre et 1 caractère spécial.',
        ),
    }),
    onSubmit: (values) => {
      Login({ email: values.email, password: values.password })
        .then((response: any) => {
          if (response?.success) {
            setUser(response)
            localforage.setItem('auth', JSON.stringify(response))
            navigate('/')
          } else {
            setHasError(true)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  })

  useEffect(() => {
    const savedEmail = localStorage.getItem('savedEmail')

    if (savedEmail && formik.values.email !== savedEmail) {
      formik.setFieldValue('email', savedEmail)
    }
  }, [formik.values.saveEmail, formik.setFieldValue])

  useEffect(() => {
    if (formik.values.saveEmail) {
      localStorage.setItem('savedEmail', formik.values.email)
    } else {
      localStorage.removeItem('savedEmail')
    }
  }, [formik.values.saveEmail, formik.values.email])
  const [visible, setVisible] = useState(false)
  const [isInputClicked, setIsInputClicked] = useState(true)

  return (
    

    <div className="flex sm:flex-col sm:gap-y-[27px] lg:justify-center sm:justify-normal max-h-screen">
      <div className=" w-1/2 sm:w-[358px] sm:mx-[16px] sm:h-[450px] lg:w-[80%]">
        <LogoConatiner retour={false} image={logoSignup} />
        <div className="mx-[186px] sm:mx-[16px] xl:mx-[90px]">
          <div className="flex items-center sm:mt-[60px]">
            <div
              className={classNames(
                'sm:text-[25px] text-[30px] text-[#1D3A70] text-left sm:font-semibold font-bold mt-[80px] sm:mt-[0px]',
                isInputClicked && 'sm:mt-[15px]',
              )}
            >
              Bonjour
            </div>
            <img src={handlogohome} className="w-6 h-6 mt-[80px] sm:mt-3 ml-3" />
          </div>
          <div className="text-[#6B7280] text-[15px] font-normal text-left sm:mt-[10px] mt-2 font-sans ">
            Connectez-vous pour accéder à votre compte
          </div>

          <form
            className={classNames('grid grid-cols-1 sm:gap-[15px] gap-[30px] sm:mt-[30px]) ', isInputClicked && 'mt-5')}
            onSubmit={formik.handleSubmit}
          >
            <div className="h-[100px]">
              <input
                className="bg-[#F9FAFB] px-[14px] py-4 rounded-xl w-full mt-4 placeholder:text-[#9CA3AF] text-[#5754E5]"
                name="email"
                placeholder="Adresse e-mail"
                type="text"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur('email')}
              />
              {formik.errors.email && formik.touched.email && (
                <div className="text-red-500 text-[14px] sm:text-[12px]">{formik.errors.email}</div>
              )}
            </div>

            <div className="relative h-[100px]">
              <input
                className="bg-[#F9FAFB] px-[14px] py-4 rounded-xl w-full  placeholder:text-[#9CA3AF] text-[#5754E5]"
                name="password"
                placeholder="Mot de passe"
                type={visible ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur('password')}
                autoComplete='off'
              />
              <img
                src={ !visible ? InvisibleEye : VisibleEye}
                alt=""
                className={classNames( 'absolute top-4 right-4 w-6 h-6 cursor-pointer' )}
                onClick={() => setVisible(!visible)}
              />
              {/* <img
                src={VisibleEye}
                alt=""
                className={classNames('absolute', visible ? 'top-4 right-4 w-6 h-6' : 'hidden')}
                onClick={() => setVisible(!visible)}
              /> */}
              {formik.errors.password && formik.touched.password && (
                <div className="text-red-500 text-[14px] sm:text-[12px]">{formik.errors.password}</div>
              )}
            </div>
            <div className="flex justify-between">
              <div className="flex items-center">
                <input
                  id="saveEmail"
                  name="saveEmail"
                  type="checkbox"
                  checked={formik.values.saveEmail}
                  onChange={formik.handleChange}
                  className="w-4 h-4 text-[#1D3A70] bg-[#F9FAFB] border-black rounded focus:ring-[#D8D0FF] dark:focus:ring-black  dark:ring-blackfocus:ring-2 cursor-pointer"
                />
                <label
                  htmlFor="saveEmail"
                  className="px-2 text-[12px] sm:text-[10px] sm:px-1 text-[#333F51] font-semibold cursor-pointer"
                >
                  Sauvegardez vos identifiants
                </label>
              </div>

              <Link to="/emailVerificationContainer" className="text-[14px] font-normal text-[#5754E5] ">
                Mot de passe oublié?
              </Link>
            </div>

            {hasError ? (
              <div className="text-red-500 text-center text-[14px] py-5 h-0">
                Veuillez vérifier l&apos;exactitude de votre adresse <br /> e-mail ou de votre mot de passe.
              </div>
            ) : (
              <div></div>
            )}

            <div className="flex justify-center mt-5">
              <button
                type="submit"
                value="Submit"
                disabled={!formik.isValid || formik.values.email === '' || formik.values.password === ''}
                className={`text-white px-[14px] py-4 text-[15px] font-medium rounded-xl w-[80%]
                  ${!formik.isValid || formik.values.email === '' || formik.values.password === '' ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#4743C9]'}`}
              >
                Se connecter
              </button>
            </div>
          </form>
        </div>
        <div className="flex items-center justify-center mt-5">
          <span className="text-[13px] font-semibold text-[#333F51]">Vous n’avez pas de compte ?</span>
          <Link to="/signup" className="text-[13px] font-semibold text-[#5754E5] pl-2">
            S’inscrire
          </Link>
        </div>
      </div>

      <img
        className={classNames(
          isInputClicked ? 'hidden sm:flex w-[50%] sm:w-[330px] sm:mt-[30px] sm:mx-[30px] sm:order-first ' : 'hidden',
        )}
        src={imageLoginMobile}
        alt=""
      />
      {/* <img className=''  src={newImageLogin} alt='image Login' /> */}
      <ImageSignUp image={newImageLogin} />
    </div>
  )
}
export default LoginContainer
