import React, { useContext, useEffect, useState } from 'react'
import { getCurrencies, getFxRate, commission } from 'helpers/api'
import classNames from 'classnames'
import FlagTun from '../../assets/flagTun.png'
import CrediPayIcon from 'components/icons/CrediPayIcon'
import BankPayIcon from 'components/icons/BankPayIcon'
import RadioFocus from '../../assets/radioFocus.svg'
import DeuIcon from '../../assets/deu.svg'
import AutIcon from '../../assets/aut.svg'
import BelIcon from '../../assets/bel.svg'
import FrIcon from '../../assets/flag-fr.png'
import GrcIcon from '../../assets/grc.svg'
import ItaIcon from '../../assets/ita.svg'
import NldIcon from '../../assets/nld.svg'
import PrtIcon from '../../assets/prt.svg'
import LuxIcon from '../../assets/lux.svg'
import GerIcon from '../../assets/ger.svg'
import drapeauTunisie from '../../assets/drapeauTunisie.svg'
import { isNumber } from 'utils/regex'
import InputCalculate from 'components/InputCalculate'
import { Currencies } from 'types/Transfert'
import { Spinner } from 'utils/loading'
import iconSelectInput from '../../assets/iconSelectInput.svg'
import InputCalculateMontantEnvoyer from 'components/InputCalculateMontantEnvoyer'
import ContainerTransfer from './containerTransfer'
import InputSelectList from './inputSelectList'
import UserContext from 'contexts/UserContext'
import { SearchPromoCodes } from 'requests/transfer'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'
import { GetStateOfUser } from 'requests/user'

interface props {
  amountD: number
  setAmountD: (value: number) => void
  selectedValue: string
  setSelectedValue: (value: string) => void
  currencyToTND?: number
  setCurrencyToTND: (value: number) => void
  modePay: string
  setModePay: (value: string) => void
  com: number
  setCom: (value: number) => void
  taux: number
  setTaux: (value: number) => void
  setDepartureCurrencyId: (value: string) => void
  setDestinationCurrencyId: (value: string) => void
  setNumStep: (value: number) => void
  euroValue: number
  setEuroValue: (value: number) => void
  dinarValue: number
  setDinarValue: (value: number) => void
  setChoisirCarte: (value: string) => void
  setBanqueName: (value: string) => void
  setIban: (value: string) => void
  setColectPointId: (value: string) => void
  setReseaux: (value: string) => void
  choisirModePayment: any
  setChoisirModePayment: (value: number) => void
  paymentMode: any
  setPaymentMode: (value: string) => void
  promoCode: any
  setPromoCode: (value: string) => void
  code: any
  valuePays: any
  setValuePays: (value: any) => void
  setFxRateFirstTransfer: (value: any) => void
  fxRateFirstTransfer: any
  functionTracks:any
}

const Montant: React.FC<props> = ({
  amountD,
  setAmountD,
  selectedValue,
  setSelectedValue,
  currencyToTND,
  setCurrencyToTND,
  modePay,
  setModePay,
  com,
  setCom,
  taux,
  setTaux,
  setDepartureCurrencyId,
  setDestinationCurrencyId,
  setNumStep,
  euroValue,
  setEuroValue,
  dinarValue,
  setDinarValue,
  setChoisirCarte,
  setBanqueName,
  setIban,
  setColectPointId,
  setReseaux,
  choisirModePayment,
  setChoisirModePayment,
  paymentMode,
  setPaymentMode,
  promoCode,
  setPromoCode,
  code,
  valuePays,
  setValuePays,
  setFxRateFirstTransfer,
  fxRateFirstTransfer,
  functionTracks,
}) => {
  const { user } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const number = parseInt(code)
  const { TransferData, setTransferData } = useContext(transferBeneficiaryContext)

  const [selectedOptionLien, setSelectedOptionLien] = useState('Carte Bancaire')
  const [isOpenLien, setIsOpenLien] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')

  const [codePromotionnel, setCodePromotionnel] = useState('')
  const [successCode, setSuccessCode] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [modeDePaiement, setModeDePaiement] = useState('')

  const handleChange = (e: any) => {
    const inputValue = e.target.value
    setPromoCode(inputValue)

    SearchPromoCodes({ promoCode: inputValue })
      .then((response: any) => {
        if (response?.success) {
          setSuccessCode(true)
        } else {
          setSuccessCode(false)
        }
      })
      .catch((err) => {
        console.log('Error searching promo codes:', err)
      })
  }

  const [error, setError] = useState(false)

  const handleEuroChangeMessage = (montant: any) => {
    if(number === 0){
      if (montant >= 15 && montant < 5001) {
        setError(false)
        setEuroValue(montant)
      } else {
        setError(true)
      }
    }else if(number === 1){
      if (montant >= 15 && montant < 5001) {
        setError(false)
        setEuroValue(montant)
      } else {
        setError(true)
      }
    }
  }

  const [nbTransactionsPaid, setNbTransactionsPaid] = useState()
  const [referralBonus, setReferralBonus] = useState()

  useEffect(()=>{
    const getState = async ()=>{
     const response =  await GetStateOfUser (user?.user?._id)
     if(response?.success){
      //console.log('response success',response)
      setNbTransactionsPaid(response?.user?.nbTransactionsPaid)
      setReferralBonus(response?.user?.referralBonus)
     }
     else{
      console.log('response failed',response)
     }
    }
    getState()
  },[])

  //start transfert dinar to dolar and dolar to dinar

  const handleEuroChange = async (euroInputValue: any) => {
    setEuroValue(euroInputValue)
    //Convertir Euro en Dinar
    const currenciess: Currencies[] = await getCurrencies()
    
    const idSender = currenciess?.filter((item) => item?.countryCode === selectedValue)
    setDestinationCurrencyId(currenciess[0]?._id)
    setDepartureCurrencyId(idSender[0]?._id)
    //console.log('idSender -- euro change', idSender)

    const fxRateCustomerr = await getFxRate(idSender[0]?._id, currenciess[0]?._id)
    //console.log('fxRateCustomerr -- euro change', fxRateCustomerr)

    const cur = Math.round((euroValue * fxRateCustomerr?.fxRateCustomer + Number.EPSILON) * 100) / 100
    const dinarInputValue = cur
    setCurrencyToTND(cur)
    setCom(fxRateCustomerr?.fxRateCustomer)

    let commisssion = null
    commisssion = await commission(idSender[0] && idSender[0]?._id, euroValue.toString())
    
    setTaux(commisssion?.value)
    //setTaux(commisssion ? commisssion?.value : 1)
    setDinarValue(dinarInputValue)
    handleEuroChangeMessage(parseFloat(euroInputValue))
    }

  
  const handleDinarChange = async (dinarInputValue: any) => {
    setDinarValue(dinarInputValue)
    
    //Convertir Dinar en Euro
    const currenciess: Currencies[] = await getCurrencies()
    const idSender = currenciess?.filter((item) => item?.countryCode === selectedValue)
    const fxRateCustomerr = await getFxRate(idSender[0]?._id, currenciess[0]?._id)
    //console.log('fxRateCustomerr -- dinar change', fxRateCustomerr)
    const euroInputValue = Math.round((dinarInputValue / fxRateCustomerr?.fxRateCustomer + Number.EPSILON) * 1000) / 1000    
    setEuroValue(euroInputValue)
    handleEuroChangeMessage(euroInputValue)
  }


   const convertEuroToDinar = async () => {
     const currenciess = await getCurrencies()

     const idCurrenciess = currenciess?.filter((item: any) => item?.code === valuePays.value)
    
     const idSender = currenciess?.filter((item: any) => item?.countryCode === user?.user?.address?.country?.code)
     setDestinationCurrencyId(idCurrenciess[0]?._id)
     setDepartureCurrencyId(idSender[0]?._id)
     const fxRateCustomerr = await getFxRate(idSender[0]?._id, idCurrenciess[0]?._id)
    
      // if (!user?.user?.nbTransactionsPaid) {
      //   const cur = Math.round((euroValue * fxRateCustomerr?.fxRateFirstTransfer + Number.EPSILON) * 100) / 100
      //   setCurrencyToTND(cur)
      //   setTaux(0)
      //   setCom(fxRateCustomerr?.fxRateFirstTransfer)
      //   setFxRateFirstTransfer(fxRateCustomerr?.fxRateFirstTransfer)
      //   return cur
      // } 

      if (!nbTransactionsPaid) {
        const cur = Math.round((euroValue * fxRateCustomerr?.fxRateFirstTransfer + Number.EPSILON) * 100) / 100
        setCurrencyToTND(cur)
        setTaux(0)
        setCom(fxRateCustomerr?.fxRateFirstTransfer)
        setFxRateFirstTransfer(fxRateCustomerr?.fxRateFirstTransfer)
        return cur
      } 
      
     const cur = Math.round((euroValue * fxRateCustomerr?.fxRateCustomer + Number.EPSILON) * 100) / 100
     setCurrencyToTND(cur)
     setCom(fxRateCustomerr?.fxRateCustomer)

     let commisssion = null
    //  if (!user?.user?.nbTransactionsPaid) {
    //    setTaux(0)
    //  } else if (successCode) {
    //    setTaux(0)
    //  } else if (user?.user?.nbTransactionsPaid >= 1) {
    //    commisssion = await commission(idSender[0] && idSender[0]?._id, euroValue.toString())
    //    //setTaux(commisssion ? commisssion?.value : 1)
    //    setTaux(commisssion?.value)
    //  }
    if (!nbTransactionsPaid) {
      setTaux(0)
    } else if (successCode) {
      setTaux(0)
    } else if (nbTransactionsPaid >= 1) {
      commisssion = await commission(idSender[0] && idSender[0]?._id, euroValue.toString())
      //setTaux(commisssion ? commisssion?.value : 1)
      setTaux(commisssion?.value)
    }
     return cur
   }

  // useEffect(() => {
  //   if (user?.user?.referralBonus && user?.user?.referralBonus > 0) {
  //     setTaux(0)
  //   }
  // }, [taux])

  useEffect(() => {
    if (referralBonus && referralBonus > 0) {
      setTaux(0)
    }
  }, [taux])

  useEffect(() => {
    if (successCode) {
      setTaux(0)
    }
  }, [successCode])

  //console.log('taux', taux)

  useEffect(() => {
    const setInitialDinarValue = async () => {
      const initialDinarValue = await convertEuroToDinar()

      if (isNaN(initialDinarValue)) {
        setDinarValue(0)
      } else {
        setDinarValue(initialDinarValue)
      }
    }
    setInitialDinarValue()
  }, [euroValue, valuePays, currencyToTND])

  //end transfert dinar to dolar and dolar to dinar

  const optionsLien = [
    { index: 0, code: 'cb', name: 'Carte Bancaire' },
    { index: 1, code: 'pi', name: 'Virement instantané' },
  ]

  const handleSelectLien = (lien: any) => {
    console.log("lientrt",lien)
    setSelectedOptionLien(lien)
    setIsOpenLien(false)
    setModeDePaiement(lien)
  }

  useEffect(() => {
    if (modeDePaiement) {
      const addMode = { ...TransferData, modeDePaiement }
      setTransferData(addMode)
    }
  }, [modeDePaiement])

  useEffect(() => {
    if (TransferData) {
      const addMode = TransferData.modeDePaiement
      console.log("addMode",addMode)
        setSelectedOptionLien(addMode ? addMode : 'Carte Bancaire')
        setModeDePaiement(addMode)
    }
  }, [TransferData])


  const handleSelect = (option: any) => {
    setSelectedOption(option.value)
  }


  useEffect(() => {
    if (optionsLien.length > 0) {
      setSelectedOptionLien(optionsLien[0].name)
      setChoisirModePayment(0)
      setPaymentMode('cb')
    }
  }, [])

  const options: { icon: any; value: string; label: string }[] = [
    { icon: drapeauTunisie, value: 'TND', label: 'TND' },
    { icon: FrIcon, value: 'FRA', label: 'FRA' },
    { icon: DeuIcon, value: 'ESP', label: 'ESP' },
    { icon: AutIcon, value: 'AUT', label: 'AUT' },
    { icon: BelIcon, value: 'BEL', label: 'BEL' },
    { icon: GrcIcon, value: 'GRC', label: 'GRC' },
    { icon: ItaIcon, value: 'ITA', label: 'ITA' },
    { icon: NldIcon, value: 'NLD', label: 'NLD' },
    { icon: PrtIcon, value: 'PRT', label: 'PRT' },
    { icon: LuxIcon, value: 'LUX', label: 'LUX' },
    { icon: GerIcon, value: 'DEU', label: 'DEU' },
  ]

  const handleCarteSelected = (number: any) => {
    setChoisirCarte(number)
  }
  // if (!loading) return <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />

  return (
    <div className="flex flex-col w-[550px] items-center sm:justify-center sm:w-auto xs:w-auto">
      <InputCalculateMontantEnvoyer
        euroValue={euroValue}
        com={com}
        taux={taux}
        dinarValue={dinarValue}
        setEuroValue={setEuroValue}
        error={error}
        handleEuroChange={handleEuroChange}
        number={number}
      />

      <ContainerTransfer
        dinarValue={dinarValue}
        handleDinarChange={handleDinarChange}
        setValuePays={setValuePays}
        valuePays={valuePays}
        number={number}
      />

      <div className="h-[90px]">
        <input
          className="text-[15px] text-[#1D3A70] placeholder:text-[#1D3A70] mx-auto sm:mt-5 sm:mx-[50px] md:mx-0 font-semibold mt-3 bg-gray-100 rounded-xl py-[11px] px-[16px] w-[258px] text-center"
          placeholder="Code Promotionnel"
          type="text"
          value={promoCode}
          name="promoCode"
          onChange={handleChange}
          maxLength={10}
          autoComplete="off"
        />
        {errorMessage && <p className="text-red-500 text-center text-[14px]">{errorMessage}</p>}

        {successCode && promoCode.length === 10 ? (
          <div className="text-green-500 text-center text-[14px]">success code envoyer</div>
        ) : !successCode && promoCode.length > 0 && promoCode.length < 10 ? (
          <div className="text-red-500 text-center text-[14px]">Veuillez entrer un code valide</div>
        ) : (
          <></>
        )}
      </div>

      {/* <InputSelectList
        euroValue={euroValue}
        setChoisirCarte={setChoisirCarte}
        setBanqueName={setBanqueName}
        setIban={setIban}
        setColectPointId={setColectPointId}
        setReseaux={setReseaux}
      /> */}

      <div className="flex flex-col mt-5 w-[550px] sm:w-[360px] 2sm:w-[340px]">
        <div className="font-semibold tex-[17px]">Mode de paiement <span className="text-[#FF0000]">*</span> </div>
        <div className="flex sm:gap-[20px]">
          <div className="h-[82px] sm:h-[90px] w-full sm:w-[360px]">
            <div className="flex flex-col w-full">
              <div className="relative inline-block mt-3">
                <input
                  type="text"
                  className="p-2 bg-[#F9FAFB] hover:cursor-pointer font-bold text-[15px] sm:h-[55px] rounded-xl w-full h-[56px] text-[#1D3A70] placeholder:text-[#1D3A70]"
                  placeholder="Choisir"
                  name="selectedOptionLien"
                  onClick={() => {
                    setIsOpenLien(!isOpenLien)
                  }}
                  value={selectedOptionLien}
                  onChange={(e) => {
                    handleSelect(e.target.value)
                    //setModeDePaiement(e.target.value)
                  }}
                  readOnly
                />
                <div className="hover:cursor-pointer absolute top-0 right-0 mt-[15px] mx-3" onClick={() => setIsOpenLien(!isOpenLien)}>
                  <img src={iconSelectInput} className={`h-6 w-6
                  ${user?.user?.address?.country?.code === 'FRA' || user?.user?.address?.country?.code === 'ITA' ? 'block' : 'hidden'}
                  `} />
                </div>

                <div
                  className={`absolute top-full left-0 z-10 sm:h-[80px] w-full shadow-selct rounded-xl text-[#1D3A70] bg-white overflow-y-auto ${
                    isOpenLien ? 'block' : 'hidden'
                  }
                  ${user?.user?.address?.country?.code === 'FRA' || user?.user?.address?.country?.code === 'ITA' ? 'block' : 'hidden'}
                  `}
                >
                  {optionsLien.map((LienList) => (
                    <div
                      key={LienList.code}
                      className={`p-2 cursor-pointer ${
                        LienList.name === selectedOptionLien ? 'bg-[#1D3A70]' : 'hover:bg-gray-100'
                      }`}
                      onClick={() => {
                        handleSelectLien(LienList.name)
                        if (LienList.index === 0) {
                          setChoisirModePayment(0)
                          setPaymentMode(LienList.code)
                        } else if (LienList.index === 1) {
                          setChoisirModePayment(1)
                          setPaymentMode(LienList.code)
                        }
                      }}
                    >
                      <div
                        className={`text-[15px] font-semibold ${
                          LienList.name === selectedOptionLien ? 'text-white' : ''
                        }`}
                      >
                        {user?.user?.address?.country?.code === 'FRA' || user?.user?.address?.country?.code === 'ITA'
                          ? LienList.name
                          : LienList.code === 'cb'
                          ? LienList.name
                          : null}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex sm:flex-col justify-center w-full sm:px-4">
        <button
          className={`text-[18px] mt-8 px-[28px] text-[#FFF] py-[16px] sm:py-3 md:my-0 rounded-xl w-[375px] sm:w-auto
          ${error ? 'bg-gray-300 ' : 'bg-[#4743C9]'}
          `}
          disabled={error}
          onClick={() => {
            setNumStep(1)
            functionTracks('1')
            handleCarteSelected(number)
          }}
        >
          Suivant
        </button>
      </div>
    </div>
  )
}
export default Montant
